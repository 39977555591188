
$blue: #5B2C86;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$darkishBlue: #a4bbca;
$green: #5B2C86;
$darkgrey: #3f3f3f;
$darkPurple: #5B2C86;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$goldyOrange: #F78D2A;
$turqoise: #5B2C86;
$lighterGrey: #CCCCCE;
$red: #ce1f4a;
$font: #54565b;
$faintYellow: rgb(252, 244, 235);
$questionOrange: rgb(233, 146, 67);
$font-family: 'Arial', sans-serif;
$font-family-bold: 'Arial Bold', sans-serif;
$font-family-medium: 'Arial Medium', sans-serif;

// @import './vars.scss';

.tab-content{
  padding: 0 50px;
}

.figure-icon-row,
.figure-content-icons{
  margin-Bottom: 20px;
  img{
    height:85px;
    width:85px;
  }
}

.figure-icon-row{
  p{
    margin: 0;
  }
}

.figure-content-icons{
  max-Width: 1000px;
}

.tab2Figure2Col{
  display: inline-flex !important;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  font-size: 16px;


  p{
    margin:0;
    line-height: normal;
  }

}

.purple-list{
  font-weight: bold;
  color: $darkPurple;
  li{
    font-weight: normal;
  }
}

@media screen and (max-width: 767px) {
  .tab2Figure2Col{
    justify-content:start;
    flex-direction: row;
    text-align: left;
  }
  .figure-content-icons{
    img{
      height:60px;
      width:60px;
    }
  }
}

.figure-content{
  position: relative;
  margin: 20px 0;
  display: inline-block;

  img{
  width: 100%;
  }
}
.small-content{
  width: 45vw;
}

.medium-content{
  width: 55vw;
}


@media screen and (max-width: 700px) {
  .medium-content{
    width: 100%;
  }
  .small-content{
    width: 100%;
  }
 }

.subheader{
  font-weight: bold;
  color: $darkPurple;
  font-size: 28px;
  font-family: 'din-2014-narrow', sans-serif;
  line-height: 1.2;

  span{
    color: $red;
    font-family: 'din-2014-narrow', sans-serif;
  }
  sup{
    font-weight: normal;
    font-size: 16px;
  }

}

.figure-header{
  font-size: 22px;
}

.ref-subheader{
  font-weight: bold;
  font-family: 'din-2014-narrow', sans-serif;
  color: $darkPurple;

  &:hover{
    cursor: pointer;
  }
}

.icon-row{
  display: flex;
  justify-content: space-evenly;
  margin: 30px auto;
}

.yellow-wrapper{
  background-color: $faintYellow;
  padding: 50px;
}

// .yellow-wrapper-content{

// }

.yellow-wrapper-rounded{
  background-color: $faintYellow;
  font-family: 'din-2014-narrow', sans-serif;
  color: $font;
  padding: 20px 60px 20px 50px;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: center;
  margin: 10px 0;
  width: 98%;

  span{
    font-family: 'din-2014-narrow', sans-serif;
  }
  sup{
    font-weight: normal;
  }

}

.twoCountUpElements{

  h2{
    padding:5px 10px;
    font-weight: bold;
    font-size: 30px;
    font-family: 'din-2014-narrow', sans-serif;
    line-height: 1.2;
    margin:0;
  }

  h2:not(:last-child){
    color: $goldyOrange;
    border-right: solid black 1px;
  }

  h2:last-child{
    color: $darkPurple;
  }
}

.figureThreeContainer{
  margin: 25px 0;
  position: relative;
  p{
    margin-top: 25px;
  }
  h2{
    margin:0;
  }
}


@media screen and (max-width: 550px) {
  .yellow-wrapper-rounded{
    border-top-right-radius: 140px;
    border-bottom-right-radius: 140px;
  }
  .yellow-wrapper{
    padding: 10px 10px;
  }

}

.check-content-wrapper{
  display: inline-flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 650px) {
  .check-content-wrapper{
    justify-content: flex-start;
  }
}

.check-content{
  display: flex;
  max-width: 280px;
  margin-left: 35px;
  flex-direction: row;
  align-items: flex-start;

  img{
    margin-right: 10px;
    margin-top: 3px;
  }
  .noMargin {
    margin:0;
  }
}

@media screen and (max-width: 550px) {
  .check-content{
    margin-left: 0px;
    max-width: 100%;
    width: 100%;
  }
}

.red-title{
  color: $red;
  font-weight: bold;
  font-family: 'din-2014-narrow', sans-serif;
}
.two-column{
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  p{
    max-width: 45%;
  }
}

.purple-bolded{
  font-weight: bold;
  color: $darkPurple;
  font-size: 15px;
  font-family: 'din-2014-narrow', sans-serif;

  sup{
    font-weight: normal;
  }
}
.references-wrapper{
  margin: 40px 50px;
  padding: 10px 0;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  p{
    font-size: 10px;
  }
}
.arrow{
  border: 1px solid $red;
  width: 8px;
  height: 8px;
  transform: rotateY(0deg) rotate(45deg);
  border-left: none;
  border-bottom: none;
  margin-left: 10px;
}

.question-wrapper{
  padding: 0 10px;
  margin-left: 15px;
  margin-top: 30px;

  .question-header{
    font-family: 'din-2014-narrow';
    color: $questionOrange;
    font-weight: bold;
    margin-bottom: 0px;

    p{
      color: $questionOrange;
      font-size: 1.8rem;
    }
  }

  h2:first-of-type{
    line-height: 25px;
    padding-bottom: 10px;
  }

  .question-answer{
    margin-left:40px;
  }
}

@media screen and (max-width: 750px) {
  .question-wrapper {
    margin-left: 15px;
  }
}


.next-article{
  font-family: 'din-2014-narrow', sans-serif;
  cursor: pointer;
  margin-left: 53px;
  text-transform: uppercase;
  color: #DC1E34;
  font-Weight: bold;
  display: flex;
  align-items: center;
  padding-Left: 50;
}

.right-content-padding{
  padding-right: 20px;
}

.mobileSections{
  display: none;
}
.continueReadingButton{
  color: $red;
  font-weight: bold;
  margin: 20px auto;
}

.mobile-row{
  display: flex;
  flex-direction: column;
}

.figOneWrapper{
  background-color: #f1f5f7;
  padding: 20px;
  position: relative;

  img{
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: -12px;
    right: -12px;
    z-index: -100;
  }
}

@media screen and (max-width: 550px) {
    .figOneWrapper{
      img{
        display: none;
      }
    }
}


.figOnesideButton{
  color:$darkishBlue;
  background-color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  padding: 8px;
  border: 1px solid $darkishBlue;

  sup{
    top: 4px;
  }
}

.activeSidebutton{
    color: white !important;
    background-color: $darkishBlue !important;
}

.activeColumnsWrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  .activeColumn{
    flex: 0 0 33%;
  }
}
.activeColumnItems{
  p{
    margin-bottom: 4px;
  }
}

.pairImages{
  height: 400px;
  margin-bottom: 30px;
}
.mobileJustify{
  justify-content: space-between;
}

.mobileJustify.rowImages {
  justify-content: flex-start;
  div {
    &:first-child {
      margin-right: 10px;
    }
  }
}

.headbubbleFigure{
  padding: 30px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  max-width: 900px;
}

.headBubbleText{
  max-width: 330px;
}

@media screen and (max-width: 1200px){
  .headbubbleFigure{
    flex-direction: column;
  }
  .headBubbleText{
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 750px) {

  .next-article{
    margin: 20px;
  }

  .headbubbleFigure{
    flex-direction: column;
  }

  .right-content-padding{
    padding-right: 0px;
    margin: 0 auto;
  }

  .tab-content{
    padding: 20px 20px 0 20px;
  }

  .mobileSections{
    display: block;
  }
  .references-wrapper{
    margin: 0 auto;
    padding: 10px 20px;
  }
  .mobile-row{
    display: none !important;
  }
  .active-toggle{
    display: flex !important;
  }
  .pairImages{
    margin: 30px auto;
  }
  .mobileJustify{
    justify-content: center;
  }
  .mobileJustify.rowImages {
    div {
      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.boldedNote{
  margin-left: 30px;
  font-weight: bold;
  font-size: 17;
}

.text-overflow-center{
  margin-left: -100%;
    margin-right: -100%;
    text-align: center;

}

@media screen and (max-width: 550px){
  .pairImages{
    width: 100%;
  }
  .imgBreakPoint{
    display: flex;
    justify-content: center;
    margin: 0 auto !important;
  }
  .boldedNote{
    margin-left: 0px;
  }
}


.IndicationLine{
  margin-top: 10px;

  p{
    color: white !important;
    font-family: 'din-2014';
    font-style: italic;
    font-size: 18px;
  }
}

.vol2-tab2-3across-wrapper{
  max-width: 1200px;
  margin-bottom: 14px;
}

.vol2-tab2-3across{
  display: flex;
  gap:10px;
  align-items: center;
  width: 270px;

  img{
    height: 80px;
  }

  p{
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .vol2-tab2-3across-wrapper{
    justify-content: start !important;
  }
  .vol2-tab2-3across{
    width: 100%;

    p{
      max-width: 100% !important;
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.tab3FigureThreeStepContent {
  position: absolute;
  font-size: 13px;
  font-family: 'din-2014-narrow';
  font-weight: bold;
  color:$darkPurple;
  animation: FadeIn 3s linear;
  animation-fill-mode: both;
}

.t3F3-1{
  left: 11.6%;
  bottom: 34.4%;
  animation-delay: 3.5s;
}

.t3F3-2{
  left: 18.2%;
  bottom: 36.3%;
  animation-delay: 3.10s;
}
.t3F3-3{
  left: 23.8%;
  bottom: 38.9%;
  animation-delay: 3.15s;
}

.t3F3-4{
 left: 29.5%;
  bottom: 43.5%;
  animation-delay: 3.20s;
}

.t3F3-5{
  left: 35.3%;
  bottom: 48.8%;
  animation-delay: 3.25s;
}

.t3F3-6{
  left: 41.3%;
  bottom: 53.5%;
  animation-delay: 3.30s;
}

.t3F3-7{
  left: 46.7%;
  bottom: 57.5%;
  animation-delay: 3.35s;
}
.t3F3-8{
  left: 52.5%;
  bottom: 62.5%;
  animation-delay: 3.40s;
}

.t3F3-9{
  left: 58.6%;
  bottom: 67.9%;
  animation-delay: 3.45s;
}

.t3F3-10{
  color: #3f3f3f;
  left: 72.5%;
  bottom: 72.8%;
  animation-delay: 3.50s;
}

.striped-circle-column{
  display: inline-flex !important;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  font-size: 16px;
}

.center-aligned-column{
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.purpleDivider{
  background-color: $darkPurple;
  border: none;
  height: 1px;
}

.shortParagraph{
  margin-bottom: 10px;
  p{
    margin-bottom: 5px;
  }
}

.purple-notBold{
  color:$darkPurple
}

.dosage-container {
  display: flex;
	margin: 0 5px;
	text-align: center;
}

.single-titration{
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5vw;
  opacity: 0;
  p{
    margin: 0;
  }

 hr{
  width: 100%;
  height: 8px;
  border: none;
 }

  img{
    width: 2.8vw;
    padding-bottom: 12px;
    height: auto;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

}

.dosage-container {

  div{
    border-bottom: $lighterGrey solid 1px;
  }

  div:not(:last-child){
    border-right: $lighterGrey solid 1px;
  }

  div:after{
    padding: 0;
    margin: 0;
    content: "";
    width: 1px;
    height: 35px;
    background-color: white;
    position: absolute;
    left: -1px;
    top: -1px;
  }

}

.bounce {
  animation: bounce 1s ease-in-out;
}

.single-titration-animate{
  animation: fadeIn 0.5s ease-in both;
}

.dosage-container div:nth-child(2) {
	animation-delay: .3s;

  img{
    animation-delay: .5s;
  }
}
.dosage-container div:nth-child(3) {
	animation-delay: .6s;
  img{
    animation-delay: .8s;
  }
}
.dosage-container div:nth-child(4) {
	animation-delay: .9s;

  img{
    animation-delay: 1.1s;
  }
}
.dosage-container div:nth-child(5) {
	animation-delay: 1.2s;
  img{
    animation-delay: 1.4s;
  }
}
.dosage-container div:nth-child(6) {
	animation-delay: 1.5s;

  img{
    animation-delay: 1.7s;
  }
}

.table-width{
  width: 50vw;
}


@media only screen and (max-width: 950px){
  .table-width{
    width: 70vw;
  }
}

@media only screen and (max-width: 750px){
  .table-width{
    width: 100%;
  }
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  40% {
    opacity: 1;
    transform: translateY(0);
  }
  55% {
    transform: translateY(-40px);
  }
  75% {
    transform: translateY(0);
  }
  85% {
    transform: translateY(-25px);
  }
  92.5% {
    transform: translateY(0);
  }
  97.5% {
    transform: translateY(-12.5px);
  }
  100% {
    transform: translateY(0);
  }
}

.tab1-fig3-vol4{
  width: 850px;
}

.tab1-fig3-vol4-mobile{
  display: none;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1150px){
  .tab1-fig3-vol4{
    display: none;
  }
  .tab1-fig3-vol4-mobile{
    display: block;
  }
}

