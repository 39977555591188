
// $blue: #5B2C86;
// $lightblue: #c8f1f9;
// $verylightblue: #e2f8fc;
// $green: #5B2C86;
// $darkgrey: #3f3f3f;
// $darkPurple: #5B2C86;
// $white: #ffffff;
// $purpler: #463a5b;
// $yellow: #fae053;
// $turqoise: #5B2C86;
// $red: #ce1f4a;
// $font: #54565b;
// $font-family: 'Arial', sans-serif;
// $font-family-bold: 'Arial Bold', sans-serif;
// $font-family-medium: 'Arial Medium', sans-serif;

// $purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255,255,255, .1) 3px, rgba(255,255,255, .1) 6px), linear-gradient($red, $darkPurple);

@import './vars.scss';

.login-container {
  flex: auto;
  display: flex;
  bottom: 0;
  width: 100%;
  background: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, 0.1) 3px, rgba(255, 255, 255, 0.1) 6px), linear-gradient(#5B2C86, #ce1f4a);

  .ant-col {
    padding: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (max-width: 650px) {
    // .ant-col{
    //   padding: 0;
    // }
  }




  .login-bg {
    background: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, 0.1) 3px, rgba(255, 255, 255, 0.1) 6px), linear-gradient(#5B2C86, #ce1f4a);
    background-size: cover !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // background-position: right bottom !important;

    &.shrink {
      font-size: 25px;
    }
  }

  .login-bg {
    background: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, 0.1) 3px, rgba(255, 255, 255, 0.1) 6px), linear-gradient(#5B2C86, #ce1f4a);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


  }

  .title--purple {
    color: $darkPurple;
  }

  .login-row {
    width: 100%;
    height: calc(100vh - 64px - 48px); // minus header and footer
    overflow-y: hidden;
    display: flex;
  }

  .paragraph {
    color: $darkPurple;
    font-size: 18px;
  }

  h1 {
    color: $darkPurple;
    font-size: 3rem !important;
    font-family: Arial, 'Sans Seriff';
    line-height: 1 !important;
  }

  .box {
    text-align: left;
    color: $darkPurple;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 99;

    h1,
    p {
      width: 100%;
      margin-bottom: 0;
      hyphens: none;
    }

    span {
      font-family: $font-family-medium;
    }
  }
}

.formWrapper {
  width: 100%;
  text-align: left;
  // This was meant ONLY when there wasn't enough
  // content on the home page to take up the full height
  // of the screen - this created a top nav, bottom footer and a middle content
  // area that took up the remaining height automatically - th second we added
  // more shit to the homscreen - thi shouldve went bye bye but we piled garbage
  // on top instead of doing it right

  // position: absolute; // not compatible with IE
  height: 100%;
  padding: 50px;
  margin-bottom: 100px;

}

@media all and (-ms-high-contrast: none) {
  .formWrapper {
    position: initial;
  }

  /* IE10 */

  *::-ms-backdrop,
  .formWrapper {
    position: initial;
  }

  /* IE11 */
}

@media screen and (max-width: 1000px) {

  .formWrapper {
    position: static !important;
    height: auto !important;
    padding: 50px 0;
    margin-bottom: 20px;
  }


  .box {
    width: 100% !important;
    text-align: center !important;
  }

  .login-form {
    margin: 20px auto !important;
  }
}

.login-form {
  .login-form-wrapper {
    width: 300px;
    text-align: center;

    h1 {
      font-weight: 300;
    }

    input {
      border-radius: 30px;
      border: 1px solid !important;
      padding: 15px;
      width: 100%;
      margin-bottom: 20px;
      background: white;
      border: none;
      outline: none;
      font-weight: 100;
    }
  }
}

.content,
.ant-layout-content {
  outline: none;
  display: flex;
  flex-direction: column !important;
  flex-flow: column !important;
  width: 100%;

  .site-layout-background,
  .site-layout-content {
    width: 100%;
    position: relative;
    z-index: 9999999999;
    flex: auto;
    display: flex;
    flex-flow: column !important;
  }
}

.fixed-width-1000 {
  max-width: 800px;
  margin: 0 auto;
}

.ant-row {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .login-container {
    height: auto !important;
  }

  // .formWrapper {
  //   position: static;
  // }
}

@media screen and (max-width: 430px) {
  .box {
    h1 {
      font-size: 3rem !important;
    }
  }

  .ant-col {
    padding: 0 sqdw20px !important;
  }
}

@media screen and (max-width: 331px) {
  .box {
    h1 {
      font-size: 3rem !important;
    }
  }

  .ant-col {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 272px) {
  .box {
    h1 {
      font-size: 2.5rem !important;
    }
  }

  .ant-col {
    padding: 10px !important;
  }
}

// .hide-scroll {
//   // scrollbar-width: none;
//   /* Firefox */
//   // -ms-overflow-style: none;
//   /* Internet Explorer 10+ */
// }

// .hide-scroll::-webkit-scrollbar {
//   /* WebKit */
//   // width: 0;
//   // height: 0;
// }

element.style {
  flex: 1 1 0%;
  position: relative;
  z-index: 0;
}

.EpiSeriesRow{
  align-items: center;
}

@media screen and (max-width: 650px) {
  .mobile-flag{
    width: 100%;

  }
  .EpiSeriesRow{
    align-items: start;
  }

  .login-container {
    flex-direction: column !important;

    .ant-col {
      justify-content: flex-start;
    }
  }

  .auth-content {
    min-height: unset !important;
  }
}

.mobileTopMenu{
  display: none;
  justify-content: space-between;

  .topMenuItem{
    background-color: #a4bbca;
    flex: 1;
    text-align: center;
    margin-top: 4px;
    color: white;
    font-weight: bold;
    padding: 8px
  }

  .topMenuItemLeft{
    margin-right: 2px;
  }
  .topMenuItemRight{
    margin-left: 2px;
  }

}

.dinFont{
  font-family: 'din-2014-narrow', sans-serif;
}

.auth-wrapper{
  color: $font;
  padding: 0 50px;

  .auth-header{
    margin-top: 30px;
    font-weight: bold;
    color: #5B2C86;
    display: block;
    font-family: 'din-2014-narrow', sans-serif;

    p{
      margin-bottom: 0px;
      font-family: 'din-2014-narrow', sans-serif;
      color: #5B2C86;
    }
    span{
      display: inline-block;
      font-family: 'din-2014-narrow', sans-serif;
    }
    span::first-letter{
      color: $red;
    }
  }

  .arrow{
    border: 2px solid $red;
    width: 10px;
    height: 10px;
    transform: rotateY(0deg) rotate(45deg);
    border-left: none;
    border-bottom: none;
    margin-left: 10px;
    font-family: 'din-2014-narrow', sans-serif;
  }

  .purple-bolded{
    font-weight: bold;
    color: $darkPurple;
    font-family: 'din-2014-narrow', sans-serif;
  }
}

@media screen and (max-width: 550px) {
  .auth-wrapper{
    padding: 0 20px;
  }
}

@media screen and (max-width: 992px){
  .mobileTopMenu{
    display: flex;
  }
}
