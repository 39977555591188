@import './vars.scss';

.patient-case-modal {
  width: 65% !important;
  top: 20px !important;

  .patient-case-title-box {
    margin: 0 50px;
    font-size: 1.1rem;

    .patient-case-form-title {
      color: $darkPurple;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .ant-modal-content {
    border-radius: 10px;
    border: 2px solid $darkPurple;

    button[type='button'] {
      border: none;
      top: -12px;
      right: -12px;
    }
  }
}

.purple-label {
  color: $darkPurple;
  font-weight: 600;
  font-size: 0.9rem;
}

.patient-case-content {
  padding: 20px 40px;
  color: $darkPurple;
  .ant-form-item-label label {
    color: $darkPurple !important;
    font-weight: 600;
  }
  .ant-input:focus,
  .ant-input:hover,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:hover,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: $darkPurple !important;
    box-shadow: none !important;
  }
}

.checkbox-wrapper {
  border: 2px solid $darkPurple;
  border-radius: 5px;
  padding: 10px;
}

.checkbox-container {
  display: flex;
  justify-content: space-evenly;
}

.checkbox-col {
  margin: 10px 0;
}

.checkbox-row-container {
  display: flex;
  span {
    color: $darkPurple;
    font-weight: 600;
  }
}

.multiAnswer span.ant-checkbox + * {
  // hide the checkbox labels and //
  // dont make them wrap so they bahave like the top label
  visibility: hidden;
  // display: none;
}

.multiAnswerLabel span.ant-checkbox + * {
  // dont make the label wrap to make the spacing benave
  // like the actual checkbox buttons
  position: relative;
  top: 20px;
  left: -30px;
}

.multiAnswerLabel span.ant-checkbox {
  visibility: hidden;
  // display: none;
}

.multiAnswerLabel .ant-checkbox-disabled + span {
  color: $darkPurple;
  cursor: default;
}

.submit-button {
  display: flex;
  justify-content: center;
  background-color: $darkPurple;
  width: 300px;
  color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  margin: 10px auto;
}

@media screen and (max-width: 1500px) {
  .patient-case-modal {
    width: 90% !important;
  }

  // .checkbox-col {
  //   min-width: 100%;
  // }
}

@media screen and (max-width: 1050px) {
  .checkbox-container {
    flex-direction: column;
    align-items: center;
  }
}

// @media screen and (max-width: 925px) {
//   .checkbox-col {
//     min-width: 100%;
//   }
// }

@media screen and (max-width: 768px) {
  .patient-case-content {
    padding: 0;
  }
}

// adress all mobile vertical screens
@media screen and (max-width: 600px) {
  .patient-case-modal {
    width: 100% !important;

    .patient-case-title-box {
      margin: 0;
      text-align: center;
    }
  }

  .checkbox-row-container {
    flex-direction: column;
    align-items: center;
    // border: 1px solid $darkPurple;

    span {
      margin: 0 auto;
    }
  }

  // .checkbox-col {
  //   margin: 5px 0;
  // }

  .multiAnswer span.ant-checkbox + * {
    visibility: visible;
  }

  .submit-button {
    max-width: 200px;
  }
}
