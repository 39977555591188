.border-button-wrapper {
  background: linear-gradient(to right, #5B2C86 0%, #DC1E34 50%, #5B2C86 100%);

  background-size: 100% 100%;
  border-radius: 30px;
  max-width: 300px !important;
  padding: 5px;
.vboothbutton {
  display: inline;
}
  &:hover {
    transform: scale(0.98);
    background-position: 100% 0;
    border-color: rgba(255, 255, 255, 0.5);
    background-size: 300% !important;
  }
}
button[type='submit'] {
  position: relative;
  font-size: 17px !important;
  text-align: center;
  border-radius: 30px !important;
  min-height: 30px;
  width: 100%;
  padding: 3px 20px;
  line-height: 1 !important;
  word-break: keep-all;
  white-space: nowrap;
  height: 40px;

  &.small {
    padding: 0;
  }
  &,
  span {
    color: white !important;
  }

  border: 1px solid white;
  border-color: rgba(255, 255, 255, 1);
  background: linear-gradient(to right, #5B2C86 0%, #DC1E34 50%, #5B2C86 100%);
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    background: transparent;
    border-color: white;
  }


}
.purple-btn {background-color: $darkPurple !important; border-color: $darkPurple;font-size: 17px;}
.border-button-wrapper {
  background-color: #5B2C86;
  /* IE6-9 */
  background-size: 100% 100%;
  border-radius: 30px;
  width: 100%;
  font-weight: 800;
  cursor: pointer;
  transition: transform background-position 0.3s cubic-bezier(0.31, 0.44, 0.445, 1.65);
  padding: 5px;
}
.border-button-wrapper:hover {
  transform: scale(0.98);
  background-position: 100% 0;
  border-color: rgba(255, 255, 255, 0.5);
  background-size: 300% !important;
}

button[type='submit'],
button[type='submit'] span {
  color: white !important;
  font-size: 17px;
}
button[type='submit']:hover,
button[type='submit']:focus {
  background: transparent;
  border-color: white;
}

.blue-button-text {
  border: 1px solid $darkPurple;
  background: none;
  button[type='submit'],
  button[type='submit'] span {
    background: 'white';
    color: $darkPurple !important;
    font-weight: bold;
    text-transform: uppercase; font-size: 17px; }

}

input[type='submit']:disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

@keyframes TransitioningBackground {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
button:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
/* on hover we animate the width to
 * 100% and opacity to 0 so the element
 * grows and fades out
 */
.myButton:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.button__solid--purple {
  transition: all 0.3s ease-in-out;
  font-size: 17px;
  &:hover {
    background-color: $darkPurple;
    font-weight: bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white !important;
  }
}
