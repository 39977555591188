$blue: #5B2C86;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #5B2C86;
$darkgrey: #3f3f3f;
$darkPurple: #5B2C86;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$turqoise: #5B2C86;
$red: #ce1f4a;
$font: #54565b;
$fontSize: 17px;
$font-family: 'Arial',
sans-serif;
$font-family-bold: 'Arial',
sans-serif;
$font-family-medium: 'Arial',
sans-serif;
$greyGradient: linear-gradient(#A4BBCA, #FFF, #A4BBCA);

$purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .1) 3px, rgba(255, 255, 255, .1) 6px),
linear-gradient($red, $darkPurple);

.sub-header {
  display: none;
  height: 35px;

  li.ant-menu-item.ant-menu-item-only-child {
    margin: 0 !important;
  }

  li {
    margin: 0 10px !important;
    font-size: $fontSize;
  }
}
 ul { margin-left: auto;}
 .logo {width: 70%;
    max-width: 400px  !important;
    height: auto !important;
    margin: 0px auto 0px 10px !important;
    @media screen and (max-width: 487px) {
      width: 210px;
    }
  }
.btn_solid--green {
  background: $darkPurple !important;
  color: white;
}

.burgerbars {
  display: none !important;
}
// .ant-layout-header{

// }

@media screen and (max-width: 1340px) {
  .ant-layout-header {
   padding: 0 5px 10px 5px !important;
    justify-content: space-between;
  }

  .burgerbars {
    display: flex !important;
  }

  .sub-header {
    display: flex;
    position: fixed;
    top: 64px;
    top: 64px;
    left: 0;
    width: 100%;
    background: white;

    ul {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      padding: 0;

      li {
        display: flex;
        align-items: center;
        line-height: 1;
        margin: 0;
      }
    }
  }

  .header .hide-desktop,
  .logout-button {
    display: none !important;
  }
}
@media screen and (max-width: 650px) {
  .ant-layout-header-noauth{
    flex-direction: column;
    justify-content: center !important;
  }
  .header-disclaimer{
    margin: 0 auto;
  }
}

@media screen and (max-width: 860px) {
  .header-disclaimer {
    font-size: 14px !important;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 750px) {
  .header-disclaimer {
    font-size: 10px;
    line-height: 13px;
    text-align: left;
    margin: 0 0 !important;
  }

  .header-pi {
    a {
      font-size: 10px !important;
    }
  }
}



@media screen and (max-width: 585px) {

  .btn__outline--purple,
  .btn__solid--purple,
  .btn__outline--green,
  .btn__solid--green {
    font-size: 8px !important;
    padding: 3px 5px !important;
  }

  .ant-layout-header::after {
    width: 100px !important;
  }
}

.btn__outline--purple {
  border: 2px solid #624b78;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
}

.btn__outline--green {
  border: 2px solid $darkPurple;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
  color: $darkPurple;

  &:hover {
    background-color: $darkPurple;
    color: white;
  }
}

.btn__solid--green {
  border: 2px solid $darkPurple;
  background: $darkPurple;
  color: white !important;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
  color: $darkPurple;

  &:hover {
    background-color: white;
    color: $darkPurple !important;
  }
}

.btn__solid--purple {
  border: 2px solid #624b78;
  background-color: #624b78;

  border-radius: 6px;
  padding: 6px 10px;
  color: white;

  &:hover {
    -webkit-text-fill-color: white;
  }
}

li.ant-menu-item {
  margin: 0 !important;
  padding: 0 !important;
}

li.ant-menu-item {
  margin: 0 !important;
}

ant-layout-header .logo {
  height: 70px !important;
  cursor: pointer;
  display: block;
}
