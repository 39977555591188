$blue: #5B2C86;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #5B2C86;
$darkgrey: #3f3f3f;
$darkPurple: #5B2C86;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$turqoise: #5B2C86;
$red: #ce1f4a;
$font: #54565b;
$font-family: 'Arial', sans-serif;
$font-family-bold: 'Arial Bold', sans-serif;
$font-family-medium: 'Arial Medium', sans-serif;

$purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255,255,255, .1) 3px, rgba(255,255,255, .1) 6px), linear-gradient($red, $darkPurple);


label.bottom-checkbox-consent {
  font-family: $font-family-bold;
  font-weight: 900 !important;
}

.regSessionCard {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
  padding: 40px 40px;
  margin: -50px auto 40px auto;
  max-width: 950px;
}

.regProgress {
  background-repeat: no-repeat;
  background-size: contain;
  height: 75px;
  margin: 30px 0px;
}

.personalInfoProgress {
  background-image: url('../img/authReg/personalInfoProgress.png');
}

.professionalInfoProgress {
  background-image: url('../img/authReg/professionalInfoProgress.png');
}


.register-back-button {
  border: 2px solid $darkPurple !important;
  border-radius: 20px !important;
  border-width: 2px !important;
  color: $darkPurple !important;
  line-height: 1px !important;
  padding: 21px 40px !important;
  font-size: 22px !important;
  font-family: $font-family;
  margin-top: 2px;
  margin-right: 40px;
}


.register-next-button, .register-submit-button {
  min-width: 350px;

  .reg-button-next-arrow {
    position: absolute;
    right: 8px;
    width: 18px;
  }
}



@media screen and (max-width: 950px) {
  .register-next-button  {
    min-width: unset;
    width: 100% !important;
    margin: auto !important;
    button[type="submit"] {
      font-size: 1em !important;
    }

    .reg-button-next-arrow {
      width: 16px;
    }

  }

  .register-submit-button {

    min-width: unset;
    margin: auto !important;
  }

  .register-back-button {
    margin-right: 0px;
  }

}

@media screen and (max-width: 600px) {

  .regProgress {
    background-position: center;
  }

  .personalInfoProgress {
    background-image: url('../img/authReg/personalInfoProgressMobile.png');
  }

  .professionalInfoProgress {
    background-image: url('../img/authReg/professionalInfoProgressMobile.png');
  }

}
