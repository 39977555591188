$blue: #5B2C86;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #5B2C86;
$darkgrey: #3f3f3f;
$darkPurple: #5B2C86;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$turqoise: #5B2C86;
$red: #ce1f4a;
$font: #54565b;
$darkRed: rgb(202, 53, 60);
$faintYellow: rgb(252, 244, 235);
$fontSize: 17px;
$font-family: 'Arial', sans-serif;
$font-family-bold: 'Arial Bold', sans-serif;
$font-family-medium: 'Arial Medium', sans-serif;

$greyGradient: linear-gradient(#A4BBCA, #FFF, #A4BBCA);

$purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .1) 3px, rgba(255, 255, 255, .1) 6px), linear-gradient($red, $darkPurple);