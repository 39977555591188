$blue: #5B2C86;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #5B2C86;
$darkgrey: #3f3f3f;
$darkPurple: #5B2C86;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$turqoise: #5B2C86;
$red: #ce1f4a;
$font: #54565b;
$font-family: 'Arial', sans-serif;
$font-family-bold: 'Arial', sans-serif;
$font-family-medium: 'Arial Medium', sans-serif;
$fontSize: 17px;
$purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255,255,255, .1) 3px, rgba(255,255,255, .1) 6px), linear-gradient($red, $darkPurple);

.isi, #scroll {
  background-color: white;
  margin-top: 2px;
  padding: 20px 50px;
  color: $font
  tr {
    position: relative;
    display: table;
  }
  table tr td:first-child:before {
    content: '';
    position: absolute;
    width: 3.5px;
    height: 3.5px;
    border-radius: 50%;
    left: 0px;
    top: 8px;
  }

  .isi-wrapper {
    .isi-preview {


      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
      background: white;
      color: $font;
      cursor: pointer;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0%, 0);
      z-index: 999999999 !important;
      width: calc(100% - 40px);
      padding: 10px;
      // box-shadow: 0 0 10px;
      background: linear-gradient(#fafafa, #f9fafa, #ebedee);
      width: 100%;
      .text {
        color: $font;
      }
    }
    .isi-higher-preview {
      bottom: 120px;
      color: $font;
    }
  }
}

.uncollapsed.isi-preview {
  // overflow-y: auto;
  transform: translate3d(-50%, 0%, 0) !important;
}

.isi-wrapper .hide.isi-preview {
  transform: translate3d(-50%, 100%, 0);
}

.text {
  color: $font;
}

.isi-arrow {
  color: black;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
  z-index: 999999999;
  transform: rotate(180deg);
}

div.isi-wrapper > div.text > * {
  line-height: 16px;
  color: $font;
  // margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .isi-wrapper {
    padding: 12px;
  }
  .isi {
    padding: 20px;
  }



  .isi .isi-wrapper .isi-preview {
    max-height: 60%;
    z-index: 999999999;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
  }

  .isi-preview::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 1200px) {
  .isi .isi-wrapper .isi-preview {
    z-index: 999999999;
  }
}
.isi-preview {
  z-index: 999999999 !important;
}

.isi-bold {
  font-weight: 600;
}

.isi-ul {
  padding-left: 15px;
}
.isi span {color: $darkPurple; margin-top: 20px;}

.isi .text {
  height: auto;
}

.isi span:active, .isi span:visited, .isi span:hover {
  color: $red;
}


