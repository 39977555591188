.Profile {


  .wrapper {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;
    padding: 50px;

    a.ant-typography {
      color: #DC1E34;
    }

  }

  ::before {
    content: unset
  }

  .btn_solid--green {
    background: #5B2C86 !important;
    color: white;
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      padding: 50px 20px;
    }

    .bg-layer {
      justify-content: center !important;
      align-items: center;
    }
  }

  .title--white {
    color: white !important;
    margin: 0 !important;
  }

}
