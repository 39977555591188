@import './vars';


.ant-radio-checked .ant-radio-inner, .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-inner {
  border-color: $darkPurple !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: $darkPurple !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after{
  border-color: $darkPurple !important;

}

.multiAnswer span.ant-radio + * {
  // hide the radio labels and //
  // dont make them wrap so they bahave like the top label
  visibility: hidden;
  white-space: nowrap;
  // font-weight: 600;
}

.multiAnswer span.ant-radio {
  // move the radio button up a bit so its centered in the row
  position: absolute;
  bottom: 20px;
}


.multiAnswerLabel span.ant-radio + * {
  // dont make the label wrap to make the spacing benave
  // like the actual radio buttons
  white-space: nowrap;
  // font-weight: 600;
  position: relative;
  top: 20px;
}

.q5-radio {
  max-width: 70px;
}

.multiAnswerLabel.ant-row {
  margin-top: -20px ;
}

.multiAnswerLabel span.ant-radio {
  visibility: hidden;
}

.multiAnswer.q5  span.ant-radio {
  position: absolute;
  top: 15px;
}

 .subLabel {
  display: block;
}


.survey-modal {
  .ant-modal-content {
    border-radius: 10px;
  }
}


@media screen and (max-width: 990px) {
  .multiAnswer span.ant-radio + * {
    // show the radio labels again
    visibility: visible;
    // line-height: 1rem;
    margin-top: 2px;
  }

  // .q4.mid span.ant-radio {
  //   position: relative;
  //   top: 20px;
  // }

  .centerLabel span.ant-radio + * {
    visibility: hidden;
    // margin-bottom: 1rem;
    position: relative;
  }

  .multiAnswer span.ant-radio {
    position: relative;
  }

  .multiAnswerLabel.ant-row {
    display: none;
  }
  // .multiAnswerLabel span.ant-radio + * {
  //   // remove the labelrow
  //   display: none;
  // }

  .multiAnswer.q5  span.ant-radio {
    position: relative;
    top: 0;
    // border: 1px solid red;
  }

  .q5-radio {
    flex-direction: column !important;
    width: 75px;
  }

  .first.q5-radio::before {
    content: '(Most Interested)';
  }

  .last.q5-radio::before {
    content: '(Least Interested)';
  }

  .mid.q5-radio {
    position: relative;
    top: 22px;
  }

  .multiAnswerLabel .q5-radio::before {
    content: '';
  }

  .subLabel {
    display: none;
  }
}

@media screen and (max-width: 600px) {

  .multiAnswer span.ant-radio {
    padding-bottom: 5px;
  }

  .multiAnswer span.ant-radio + * {
    // display: inline-block;
    // white-space: wrap !important;
    white-space: break-spaces !important;
    // word-break: break-all;
    line-height: 1rem;
  }

}

@media screen and (max-width: 499px) {


  .q4 {
    .ant-radio-wrapper.first {
      position: relative;
      left: 5px !important;
    }
    .ant-radio-wrapper.mid {
      position: relative;
      top: 8px !important;
    }
    .ant-radio-wrapper.last {
      position: relative;
      right: 15px !important;
    }
  }

  .first.q5-radio {
    position: relative;
    left: 15px;
    bottom: 1px;
  }
  .last.q5-radio {
    position: relative;
    right: 15px;
    bottom: 1px;
  }
  .mid.q5-radio {
    top: 30px;
  }

}
