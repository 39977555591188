.Content {
  outline: none;
  display: flex;
  flex-direction: column !important;
  flex-flow: column !important;

  .site-layout-background,
  .site-layout-content {
    width: 100%;
    position: relative;
    z-index: 999999999;
    flex: auto;
    display: flex;
    flex-flow: column !important;

  }
}

.fixed-width-1000 {
  max-width: 800px;
  margin: 0 auto;
}


@media screen and (max-width: 650px) {
  main.Content {
    min-height: 0 !important;
  }
}
