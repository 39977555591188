.header-active-underline-hr {
  display: none;
}

.header-active-underline.ant-menu-item-selected .header-active-underline-span {
  position: absolute;
  background-color: #5b2c86;
  height: 2px;
  bottom: -6px;
  left: 0;
  width: 100%;
}

.header-item span {
  font-family: 'din-2014-narrow', sans-serif;
}

.share-modal {
  position: relative;
}

/* .share-modal .ant-modal-content .ant-modal-close {
  position: absolute;
  top: -50px;
  right: -20px;
} */

.share-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon svg {
  font-size: 24px;
}

.share-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon svg path {
  color: #ffffff !important;
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0;
}

@media screen and (max-width: 600px) {
  .header-item {
    padding: 0 5px !important;
  }

  .ant-input,
  .ant-select {
    font-size: 16px;
  }
}

.purple-btn {
  background-color: #875799;
}

.purple-btn button {
  background-color: #875799;
}

.new_indications_h2_bg_title {
  position: relative;
  z-index: 0;
}

.new_indications_h2_bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: -185px;
  z-index: -1;
  background-image: linear-gradient(to right, transparent, #b18bb6, transparent);
}

@media screen and (max-width: 1020px) {
  .new_indications_h2_bg {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to right, transparent, #b18bb6, transparent);
  }
}

.register_banner_parent {
  position: relative;
  top: 0;
  left: 0;
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
}
.register_banner_bg {
  position: relative;
  top: 0;
  left: 0;
}
.register_banner_content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 50px;
}
.register_banner_profile_img {
  max-width: 130px;
  border-radius: 50%;
  margin-right: 10px;
}
.register_banner_content_text {
  color: white;
  font-size: 24px;
  font-weight: 900;
  text-align: left;
  line-height: 1.2;
}
.register_banner_button {
  max-width: 200px;
  margin-top: 10px;
}

@media screen and (max-width: 940px) {
  .register_banner_parent {
    max-width: 95%;
  }
  .register_banner_content {
    padding: 0 20px;
  }
  .register_banner_profile_img {
    max-width: 120px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .register_banner_content_text {
    font-size: 20px;
  }
  .register_banner_button {
    max-width: 180px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .register_banner_profile_img {
    max-width: 100px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .register_banner_content_text {
    font-size: 18px;
  }
  .register_banner_button {
    max-width: 180px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 769px) {
  .register_banner_parent {
    max-width: 100%;
  }
}

@media screen and (max-width: 715px) {
  .register_banner_content_text {
    font-size: 14px;
  }
  .register_banner_button {
    max-width: 150px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 545px) {
  .register_banner_profile_img {
    max-width: 80px;
  }
  .register_banner_content_text {
    font-size: 12px;
  }
  .register_banner_button {
    max-width: 120px;
    margin-top: 10px;
  }
}

#itc-header ul.ant-menu-horizontal {
  border-bottom: 0;
}

.register_modal_cancel button {
  color: #5b2c86 !important;
  border: 1px solid #5b2c86;
}

.register_modal_cancel button:hover {
  color: #5b2c86 !important;
  border: 1px solid #5b2c86;
}

.ant-modal .ant-modal-content button.ant-modal-close {
  background: #ffffff;
  position: absolute;
  width: auto;
  padding: 0;
  height: auto;
}

.ant-modal .ant-modal-content .ant-modal-body {
  padding-top: 50px;
}

.ant-modal-content
  .ant-modal-body
  .ant-spin-nested-loading
  .ant-spin-container
  .share-email
  .share_modal
  div
  button {
  background: transparent !important;
  color: #5b2c86 !important;
}

.ant-avatar > img {
  object-fit: contain;
}

.endmatter {
  color: #54565b !important;
}

.endmatter a {
  color: #54565b !important;
}

.endmatter div {
  color: #54565b !important;
}

h5.program-objectives {
  color: #54565b !important;
}

.sessioncardstandard .ant-divider {
  border-top: 0.7px solid #54565b;
}

.regprofile .anticon-edit svg path {
  color: #ce1f4a !important;
  fill: #ce1f4a !important;
}

.patientbarbtn button {
  background: #5b2c86;
}
.patientbarbtn button:hover {
  background: #5b2c86;
}



h2#register-form-title.ant-typography,
h3#register-form-title.ant-typography,
h4#register-form-title.ant-typography {
  color: #5b2c86 !important;
}

/* span#register-form-summary.ant-typography {
  color: unset !important;
} */

/* span#register-form-splat.ant-typography {
  color: unset !important;
} */

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-size: 19px;
}

#itc-header .ant-menu-title-content span {
  font-size: 15px;
}

#sessioncard-more-less {
  color: #5b2c86 !important;
}

.wrapper div div .welcome-subtitle {
  color: white !important;
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
}

.contactSpecialistModal div.ant-modal-content div.ant-modal-body div.ant-modal-confirm-btns {
  display: none;
}

.activeColumnBottomBorders {
  display: none;
}

@media screen and (max-width: 490px) {
  .activeColumn0 {
    border-left: 1px solid #5b2c86;
  }
  .activeColumn1 {
    border-right: 1px solid #5b2c86;
  }
  .activeColumnWithBorders {
    margin-bottom: 30px;
  }
  .activeColumnBottomBorders {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: -10px;
    justify-content: center;
    align-items: center;
    color: #5b2c86;
  }
}

.cardAvatarDetailsWrapper {
  flex-direction: row !important;
}

.cardAvatarDetailsWrapperDivider {
  display: none;
}

@media screen and (max-width: 1330px) {
  .cardAvatarDetailsWrapper {
    padding-top: 10px;
  }
}

@media screen and (max-width: 636px) {
  .cardAvatarDetailsWrapperDivider {
    display: block;
  }
}

.sessionCardAvatarWrapper {
  flex: 1;
  min-width: 275px;
  max-width: 100%;
}

.cardAvatar img {
  object-fit: contain;
  object-position: left;
}

.cardAvatarDetailsWrapperAuth {
  padding-top: 10px;
}

.cardAvatarDetailsWrapperDividerBottom {
  display: none;
}

.cardAvatarDetailsWrapperDividerAuth {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.patientBar {
  display: flex;
  margin: 0 auto !important;
  flex-direction: row;
  position: relative;
  padding: 50px;
  max-width: 1200px;
  min-height: 300px;
}

@media screen and (max-width: 948px) {
  .bg-layer .layout.patientBar {
    flex-direction: column-reverse !important;
    padding: 10px 5px 0 5px;
  }

  .patientBarWrapper {
    padding: 0;
  }

  .bg-layer .layout.patientBar img.laptop {
    margin: 0 0 5px 0 !important;
    width: 90% !important;
  }
}

.headerLogoNoAuth {
  align-self: center;
}

.noAuthTopHeader .hamburger-react {
  display: none;
}

.mobileActiveTab {
  display: none;
}

.mobileLoginTab {
  display: none;
}

@media screen and (max-width: 750px) {
  .noAuthTopHeader .hamburger-react {
    display: block;
  }
  .epiTabs div.ant-tabs-nav {
    display: none;
  }
  .mobileActiveTab,
  .mobileLoginTab {
    background-color: #a4bbca;
    display: block;
    color: white;
    font-weight: 700;
    padding: 8px;
    text-align: center;
    margin-top: 4px;
  }
  .mobileLoginTab {
    margin-bottom: 5px;
  }
  .mobileActiveTabLoggedIn {
    margin-top: 0;
  }
}

@media screen and (max-width: 992px) {
  .epiTabs div.ant-tabs-nav {
    margin-top: 4px;
  }
}

.loginInput div {
  width: 100%;
}

.userLookupFormSearchWrapper div.ant-form-item-control {
  width: 100%;
}

.question-wrapper-with-yellow-thing {
  padding-bottom: 140px;
  margin-left: 0;
  /* REMOVE ALL MARGIN AND REPLACE WITH PADDING
    question wrapper 50 margin 30 padding
  */
  padding-left: 25px;
}

@media screen and (max-width: 716px) {
  .question-wrapper-with-yellow-thing {
    padding-bottom: 180px;
  }
}
@media screen and (max-width: 556px) {
  .question-wrapper-with-yellow-thing {
    padding-bottom: 220px;
  }
}

.orangeCallout {
  background-color: #f37821;
  display: flex;
  line-height: 1;
  color: white;
  width: calc(100% + 100px);
}

@media screen and (max-width: 900px) {
  .orangeCallout {
    width: calc(100% + 20px);
  }
}

.epiMenuOption {
  display: flex !important;
  color: white;
  border-bottom: 2px solid white;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

/* Animations */
.figuretwo-bubble-wrapper {
  position: absolute;
  height: 80%;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  gap: 0 5%;
}

.figuretwo-smallbubble-wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0 5%;
}

.figuretwo-bubble {
  opacity: 0;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -ms-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
  min-width: 0;
}

/* .figuretwo-bubble1 {
} */

.figuretwo-bubble2 {
  transition-delay: 250ms;
}

.figuretwo-bubble3 {
  transition-delay: 500ms;
}

.figuretwo-bubble4 {
  transition-delay: 750ms;
}

.figuretwo-bubble5 {
  transition-delay: 1000ms;
}

.figuretwo-bubble6 {
  transition-delay: 1250ms;
}

.figuretwo-bubble7 {
  transition-delay: 1500ms;
  max-height: 90%;
}

.figuretwo-bubble.animate {
  opacity: 1;
}

.figurethree-bars {
  position: absolute;
  bottom: 190px;
  width: 100%;
  left: 0;
  padding: 0 5%;
  max-height: 50%;
}

.figurethree-bars.animate {
  -webkit-animation-name: bottomUp;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
  animation-name: bottomUp;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

@keyframes bottomUp {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

.donut-graph {
  position: absolute;
  bottom: 20%;
  right: 13%;
  height: 55%;
  width: 60% !important;
}

.donut-graph svg {
  width: 100%;
}

.donut-label-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 1;
  position: absolute;
}

.donut-label-percent-text {
  font-size: 36px;
  font-weight: 900;
  display: flex;
}

.donut-label-percent-sign {
  font-size: 24px;
  font-weight: 900;
}

.donut-label36-v4 {
  color: #94acbe;
  text-align: right;
}

.donut-label46-v4 {
  color: #481b72;
}

.donut-label46 {
  color: #481b72;
  bottom: 12%;
  left: 75%;
}

.donut-label12 {
  color: #d00028;
  text-align: right;
  bottom: 9%;
  left: 7%;
}

.donut-label12 .donut-label-percent-text {
  justify-content: flex-end;
  margin-right: 0 !important;
}

.donut-label6 {
  color: #f37821;
  text-align: right;
  bottom: 34%;
  left: 1%;
}

.donut-label6 .donut-label-percent-text {
  justify-content: flex-end;
  margin-right: 0 !important;
}

.donut-label36 {
  color: #94acbe;
  text-align: right;
  bottom: 64%;
  left: 5%;
}

.donut-label36 .donut-label-percent-text {
  justify-content: flex-end;
  margin-right: 0 !important;
}

.donut-label36-v4 .donut-label-percent-text {
  justify-content: flex-end;
  margin-right: 0 !important;
}

.donut-vol4-number {
  font-family: 'din-2014-narrow';
}
.donut-vol4-text {
  font-family: 'din-2014-narrow';
}

.donut-36-bar.bar1 {
  bottom: 77%;
  left: 5%;
}

.donut-36-bar.bar2 {
  bottom: 57%;
  left: 5%;
}

.donut-brain {
  position: absolute;
  width: 15%;
  bottom: 36%;
  left: 50%;
}

.donut-brain.animate__animated {
  animation-duration: 2s;
  animation-delay: 1s;
}

.figure-bar.donut-line-gradient {
  background: linear-gradient(to right, #481972, #ce0029);
  margin-right: 0 !important;
  height: 3px !important;
}

.figure-bar.donut-line-gradient.animate {
  animation-duration: 2s !important;
}

.donut-label-wrapper.animate__animated {
  animation-duration: 2s;
  animation-delay: 1s;
}

.donut-label36.animate__animated {
  animation-duration: 2s;
  animation-delay: 1s;
}

.donut-label36-v4.animate__animated {
  animation-duration: 2s;
  animation-delay: 1s;
}

.figuretwo-bg {
  max-height: 100%;
}

.tab2-figure2.bars-wrapper {
  position: absolute;
  bottom: 180px;
  left: 59px;
  width: 70% !important;
  height: 40px;
}

.tab2-figure2.bars {
  width: 0 !important;
  height: 40px;
}

.tab2-figure2.bars.animate {
  -webkit-animation-name: leftRight;
  -webkit-animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRight;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.tab1-figure1.vol2.bars.animate {
  -webkit-animation-name: leftRight;
  -webkit-animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRight;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.tab1-figure1.vol2.bars-wrapper {
  position: absolute;
  bottom: 180px;
  left: 59px;
  width: 70% !important;
  height: 40px;
}

.tab1-figure1.vol2.bars {
  width: 0;
  height: 40px;
}

@keyframes leftRight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.tab2-figurefour-bar-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-weight: 900;
  font-size: 20px;
  bottom: 25%;
  width: 24px;
}

.tab2-figurefour-bar-wrapper.orange {
  color: #f37821;
}
.tab2-figurefour-bar-wrapper.purple {
  color: #481474;
}

.tab2-figurefour-bar {
  width: 10px;
  border-radius: 5px 5px 0 0;
  height: 100%;
}

.tab2-figurefour-bar.orange {
  background-color: #f37821;
}
.tab2-figurefour-bar.purple {
  background-color: #481474;
}

.tab2-figurefour-bar-wrapper.bar1 {
  left: 20%;
  height: 50%;
}

.tab2-figurefour-bar-wrapper.bar2 {
  left: 24%;
  height: 30%;
}

.tab2-figurefour-bar-wrapper.bar3 {
  left: 36%;
  height: 25%;
}

.tab2-figurefour-bar-wrapper.bar4 {
  left: 40%;
  height: 28%;
}

.tab2-figurefour-bar-wrapper.bar5 {
  left: 53%;
  height: 15%;
}

.tab2-figurefour-bar-wrapper.bar6 {
  left: 57%;
  height: 27%;
}

.tab2-figurefour-bar-wrapper.bar7 {
  left: 69%;
  height: 10%;
}

.tab2-figurefour-bar-wrapper.bar8 {
  left: 73%;
  height: 23%;
}

.tab2-figurefour-bar.animate {
  -webkit-animation-name: bottomUp;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
  animation-name: bottomUp;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

.tab2-figurethree {
  opacity: 0;
}
.tab2-figurethree.animate__animated.animate__zoomIn {
  animation-duration: 750ms;
  opacity: 1;
}
.tab2-figurethree.animate__animated.animate__zoomIn.img2 {
  animation-delay: 500ms;
}
.tab2-figurethree.animate__animated.animate__zoomIn.img3 {
  animation-delay: 1000ms;
}

.tab3-figureone-studyonedotted {
  position: absolute;
  bottom: 32.5%;
  left: 60%;
  z-index: 1;
  height: 0;
}

.tab3-figureone-studyoneline1 {
  position: absolute;
  left: 20%;
  bottom: 34%;
  width: 0;
}

.line-label {
  position: absolute;
  font-weight: 900;
  font-size: 1vw;
}

.tab3-figureone-studyoneline2-label {
  color: #481b72;
}

.tab3-figureone-studyoneline2-label.label1 {
  bottom: 49%;
  left: 21.3%;
}

.tab3-figureone-studyoneline2-label.label2 {
  bottom: 68%;
  left: 46%;
}

.tab3-figureone-studyoneline2-label.animate__animated {
  animation-delay: 4s;
}

.tab3-figureone-studyoneline2 {
  position: absolute;
  left: 20%;
  bottom: 41%;
  width: 0;
  max-height: 30%;
}

.tab3-figureone-studytwodotted1 {
  position: absolute;
  bottom: 33.2%;
  left: 42%;
  z-index: 1;
}

.tab3-figureone-studytwodotted2 {
  position: absolute;
  bottom: 33.2%;
  left: 87%;
  z-index: 1;
}

.tab3-figureone-studytwoline1 {
  position: absolute;
  bottom: 35.5%;
  left: 19%;
  width: 0;
}

.tab3-figureone-studytwoline2 {
  position: absolute;
  bottom: 39%;
  left: 19%;
  width: 0;
  max-height: 6%;
}

.tab3-figureone-studytwoline2-label {
  color: #f37821;
  bottom: 45%;
  left: 45%;
}

.tab3-figureone-studytwoline2-label.animate__animated {
  animation-delay: 4s;
}

.tab3-figureone-studytwoline3 {
  position: absolute;
  bottom: 44.5%;
  left: 26%;
  width: 0;
  max-height: 10%;
}

.tab3-figureone-studytwoline3-label {
  bottom: 55%;
  left: 45%;
  color: #481b72;
}

.tab3-figureone-studytwoline3-label.animate__animated {
  animation-delay: 5s;
}

.tab3-figureone-studytwoline4 {
  position: absolute;
  bottom: 54%;
  left: 34%;
  width: 0;
  max-height: 20%;
}

.tab3-figureone-studytwoline4-label {
  bottom: 69%;
  left: 45%;
  color: #d00028;
}

.tab3-figureone-studytwoline4-label.animate__animated {
  animation-delay: 6s;
}

.tab3-figureone-studyonedotted.animate {
  -webkit-animation-name: bottomUpStudyOneDotted;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: bottomUpStudyOneDotted;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.tab3-figureone-studyoneline1.animate {
  -webkit-animation-name: leftRightStudyOneLineOne;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRightStudyOneLineOne;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.tab3-figureone-studyoneline2.animate {
  -webkit-animation-name: leftRightStudyOneLineTwo;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRightStudyOneLineTwo;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

@keyframes bottomUpStudyOneDotted {
  0% {
    height: 0%;
  }
  100% {
    height: 37.8%;
  }
}

@keyframes leftRightStudyOneLineOne {
  0% {
    width: 0%;
  }
  100% {
    width: 76%;
  }
}

@keyframes leftRightStudyOneLineTwo {
  0% {
    width: 0%;
  }
  100% {
    width: 82%;
  }
}

.tab3-figureone-studytwodotted1.animate,
.tab3-figureone-studytwodotted2.animate {
  -webkit-animation-name: bottomUpStudyTwoDotted;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: bottomUpStudyTwoDotted;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes bottomUpStudyTwoDotted {
  0% {
    height: 0%;
  }
  100% {
    height: 40.39%;
  }
}

.tab3-figureone-studytwoline1.animate {
  -webkit-animation-name: leftRightStudyTwoLineOne;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRightStudyTwoLineOne;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.tab3-figureone-studytwoline2.animate {
  -webkit-animation-name: leftRightStudyTwoLineTwo;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRightStudyTwoLineTwo;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
.tab3-figureone-studytwoline3.animate {
  -webkit-animation-name: leftRightStudyTwoLineThree;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRightStudyTwoLineThree;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}
.tab3-figureone-studytwoline4.animate {
  -webkit-animation-name: leftRightStudyTwoLineFour;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRightStudyTwoLineFour;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

@keyframes leftRightStudyTwoLineOne {
  0% {
    width: 0%;
  }
  100% {
    width: 69%;
  }
}
@keyframes leftRightStudyTwoLineTwo {
  0% {
    width: 0%;
  }
  100% {
    width: 69%;
  }
}
@keyframes leftRightStudyTwoLineThree {
  0% {
    width: 0%;
  }
  100% {
    width: 62%;
  }
}
@keyframes leftRightStudyTwoLineFour {
  0% {
    width: 0%;
  }
  100% {
    width: 54%;
  }
}

.tab3-figure3.bar1 {
  bottom: 51%;
  left: 9%;
}
.tab3-figure3.bar2 {
  bottom: 42%;
  left: 9%;
}
.tab3-figure3.bar3 {
  bottom: 51%;
  left: 56.6%;
}
.tab3-figure3.bar4 {
  bottom: 42%;
  left: 56.6%;
}
.tab3-figure3-pvalue {
  position: absolute;
  bottom: 44%;
  left: 30.5%;
  width: 18% !important;
  height: 14%;
}

.tab3-figure3-pvalue.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
}

.figure-bar-wrapper {
  position: absolute;
  display: flex;
  font-weight: 900;
  font-size: 20px;
  align-items: center;
  gap: 5px;
}

.figure-bar-wrapper.column {
  flex-direction: column;
  width: 24px;
  justify-content: flex-end;
}

.figure-bar-wrapper.row {
  flex-direction: row-reverse;
  height: 24px;
  justify-content: flex-end;
}

.figure-bar-wrapper.grey {
  color: #94acbe;
}
.figure-bar-wrapper.purple {
  color: #481b72;
}
.figure-bar-wrapper.orange {
  color: #f37821;
}
.figure-bar-wrapper.red {
  color: #d00028;
}

.figure-bar.column {
  width: 10px;
  border-radius: 5px 5px 0 0;
}
.figure-bar.row {
  height: 10px;
  border-radius: 0 5px 5px 0;
}

.figure-bar.grey {
  background-color: #94acbe;
}
.figure-bar.purple {
  background-color: #481b72;
}
.figure-bar.orange {
  background-color: #f37821;
}
.figure-bar.red {
  background-color: #d00028;
}

.tab3-figure4 {
  bottom: 31%;
  z-index: 2;
}

.tab3-figure4.bar1 {
  left: 14%;
}
.tab3-figure4.bar2 {
  left: 19%;
}
.tab3-figure4.bar3 {
  left: 33%;
}
.tab3-figure4.bar4 {
  left: 38%;
}
.tab3-figure4.bar5 {
  left: 55%;
}
.tab3-figure4.bar6 {
  left: 60%;
}
.tab3-figure4.bar7 {
  left: 75%;
}
.tab3-figure4.bar8 {
  left: 80%;
}
.tab3-figure4-zero {
  position: absolute;
  bottom: 20.3%;
  left: 11.3%;
  width: 79.4% !important;
  opacity: 0;
}
.tab3-figure4-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.figure-bar.column.animate {
  -webkit-animation-name: bottomUp;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
  animation-name: bottomUp;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

.figure-bar.row.animate {
  -webkit-animation-name: leftRight;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
  animation-name: leftRight;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

@keyframes bottomUp {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

.circles-wrapper {
  display: flex;
  gap: 10px;
}
.circle-wrapper {
  position: relative;
}
.circle-fill {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
}
.circle-fill.animate__animated.animate__bounceIn {
  animation-duration: 1s;
  opacity: 1;
}
.circle-text {
  position: absolute;
  top: 19%;
  left: 22%;
  opacity: 0;
  width: 55%;
}

.circle-text.animate__animated.animate__fadeIn {
  animation-duration: 2s;
  opacity: 1;
}
.circle-fill.circle2,
.circle-text.circle2 {
  animation-delay: 1s;
}
.circle-fill.circle3,
.circle-text.circle3 {
  animation-delay: 2s;
}
.circle-fill.circle4,
.circle-text.circle4 {
  animation-delay: 3s;
}

.tab3-figure6 {
  bottom: 20%;
}

.tab3-figure6.bar1 {
  left: 19%;
}

.tab3-figure6.bar2 {
  left: 39%;
}

.tab3-figure6.bar3 {
  left: 59%;
}

.tab3-figure6.bar4 {
  left: 78%;
}

.tab3-figure6-pvalue1 {
  position: absolute;
  bottom: 44%;
  left: 20%;
  width: 20% !important;
  height: 12%;
}
.tab3-figure6-pvalue2 {
  position: absolute;
  bottom: 52.4%;
  left: 20%;
  width: 40% !important;
  height: 19%;
}
.tab3-figure6-pvalue3 {
  position: absolute;
  bottom: 67.4%;
  left: 20%;
  width: 59% !important;
  height: 8%;
}
.tab3-figure6-pvalue1.animate__animated {
  animation-duration: 2s;
  animation-delay: 2s;
}
.tab3-figure6-pvalue2.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
}
.tab3-figure6-pvalue3.animate__animated {
  animation-duration: 2s;
  animation-delay: 4s;
}

.tab3-figure7 {
  bottom: 29.8%;
  z-index: 2;
}

.tab3-figure7.bar1 {
  left: 9%;
}
.tab3-figure7.bar2 {
  left: 12%;
}
.tab3-figure7.bar3 {
  left: 15%;
}
.tab3-figure7.bar4 {
  left: 18%;
}
.tab3-figure7.bar5 {
  left: 33%;
}
.tab3-figure7.bar6 {
  left: 36%;
}
.tab3-figure7.bar7 {
  left: 39%;
}
.tab3-figure7.bar8 {
  left: 42%;
}
.tab3-figure7.bar9 {
  left: 56%;
}
.tab3-figure7.bar10 {
  left: 59%;
}
.tab3-figure7.bar11 {
  left: 62%;
}
.tab3-figure7.bar12 {
  left: 65%;
}
.tab3-figure7.bar13 {
  left: 79%;
}
.tab3-figure7.bar14 {
  left: 82%;
}
.tab3-figure7.bar15 {
  left: 85%;
}
.tab3-figure7.bar16 {
  left: 88%;
}
.tab3-figure7-zero {
  position: absolute;
  bottom: 20.2%;
  left: 3.9%;
  width: 93.1% !important;
  opacity: 0;
}
.tab3-figure7-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab1-figure3 {
  bottom: 36%;
}
.tab1-figure3.figure-bar-wrapper {
  width: 18px;
  font-size: 14px;
}
.tab1-figure3 .figure-bar {
  width: 6px;
}
.tab1-figure3.bar1 {
  left: 5%;
}
.tab1-figure3.bar2 {
  left: 7%;
}
.tab1-figure3.bar3 {
  left: 13%;
}
.tab1-figure3.bar4 {
  left: 15%;
}
.tab1-figure3.bar5 {
  left: 20%;
}
.tab1-figure3.bar6 {
  left: 22%;
}
.tab1-figure3.bar7 {
  left: 28%;
}
.tab1-figure3.bar8 {
  left: 30%;
}
.tab1-figure3.bar9 {
  left: 36%;
}
.tab1-figure3.bar10 {
  left: 38%;
}
.tab1-figure3.bar11 {
  left: 44%;
}
.tab1-figure3.bar12 {
  left: 46%;
}
.tab1-figure3.bar13 {
  left: 52%;
}
.tab1-figure3.bar14 {
  left: 54%;
}
.tab1-figure3.bar15 {
  left: 60%;
}
.tab1-figure3.bar16 {
  left: 62%;
}
.tab1-figure3.bar17 {
  left: 68%;
}
.tab1-figure3.bar18 {
  left: 70%;
}
.tab1-figure3.bar19 {
  left: 76%;
}
.tab1-figure3.bar20 {
  left: 78%;
}
.tab1-figure3.bar21 {
  left: 84%;
}
.tab1-figure3.bar22 {
  left: 86%;
}
.tab1-figure3.bar23 {
  left: 92%;
}
.tab1-figure3.bar24 {
  left: 94%;
}
.tab2-figure2 {
  left: 18%;
}
.tab2-figure2.bar1 {
  bottom: 51%;
}
.tab2-figure2.bar2 {
  bottom: 43%;
}
.tab1-figure1-vol2 {
  bottom: 16.2%;
  z-index: 2;
}

.tab1-figure1-vol2.bar1 {
  left: 17%;
}
.tab1-figure1-vol2.bar2 {
  left: 36.5%;
}
.tab1-figure1-vol2.bar3 {
  left: 56.5%;
}
.tab1-figure1-vol2.bar4 {
  left: 75%;
}

.tab3-figure2-vol2 {
  bottom: 20.2%;
  z-index: 2;
}

.tab3-figure2-vol2.bar1 {
  left: 19.8%;
}

.tab3-figure2-vol2.bar2 {
  left: 39.2%;
}
.tab3-figure2-vol2.bar3 {
  left: 58.8%;
}
.tab3-figure2-vol2.bar4 {
  left: 78.2%;
}

.tab3-figure2-vol2-zero {
  position: absolute;
  bottom: 44.4%;
  left: 21%;
  width: 58.4% !important;
  opacity: 0;
}

.tab3-figure2-vol2-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab1-figure3-vol3 {
  bottom: 30.2%;
  z-index: 2;
}

.tab1-figure3-vol3.bar1 {
  left: 8%;
}

.tab1-figure3-vol3.bar2 {
  left: 12%;
}

.tab1-figure3-vol3.bar3 {
  left: 16%;
}

.tab1-figure3-vol3.bar4 {
  left: 20%;
}

.tab1-figure3-vol3.bar5 {
  left: 26%;
}

.tab1-figure3-vol3.bar6 {
  left: 30%;
}

.tab1-figure3-vol3.bar7 {
  left: 34%;
}

.tab1-figure3-vol3.bar8 {
  left: 38%;
}

.tab1-figure3-vol3.bar9 {
  left: 44%;
}

.tab1-figure3-vol3.bar10 {
  left: 48%;
}

.tab1-figure3-vol3.bar11 {
  left: 52%;
}

.tab1-figure3-vol3.bar12 {
  left: 56%;
}

.tab1-figure3-vol3.bar13 {
  left: 62%;
}

.tab1-figure3-vol3.bar14 {
  left: 66%;
}

.tab1-figure3-vol3.bar15 {
  left: 70%;
}

.tab1-figure3-vol3.bar16 {
  left: 74%;
}

.tab1-figure3-vol3.bar17 {
  left: 80%;
}

.tab1-figure3-vol3.bar18 {
  left: 84%;
}

.tab1-figure3-vol3.bar19 {
  left: 88%;
}

.tab1-figure3-vol3.bar20 {
  left: 92%;
}

.headbubble-wrapper {
  position: relative;
  height: 300px;
  width: 100%;
  margin-left: 50px;
}

.silhouette-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 220px;
}

.headBubbleOne {
  width: 160px;
  position: absolute;
  top: 22%;
  left: calc(50% - 220px);
  opacity: 0;
}
.headBubbleOne.animate__animated.animate__zoomIn.img1 {
  animation-duration: 700ms;
  opacity: 1;
}
.headBubbleTwo {
  width: 160px;
  position: absolute;
  top: -10%;
  left: calc(50% - 140px);
  opacity: 0;
}
.headBubbleTwo.animate__animated.animate__zoomIn.img2 {
  animation-duration: 1400ms;
  opacity: 1;
}
.headBubbleThree {
  width: 200px;
  position: absolute;
  top: -8%;
  left: calc(50% + 4px);
  opacity: 0;
}
.headBubbleThree.animate__animated.animate__zoomIn.img3 {
  animation-duration: 2100ms;
  opacity: 1;
}
.headBubbleFour {
  width: 160px;
  position: absolute;
  top: 28%;
  left: calc(50% + 60px);
  opacity: 0;
}
.headBubbleFour.animate__animated.animate__zoomIn.img4 {
  animation-duration: 2800ms;
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .headbubble-wrapper {
    margin-top: 50px;
    margin-left: 0px;
  }
}

.tab3-vol2-figureThree-zero {
  position: absolute;
  bottom: 36.3%;
  left: 55.3%;
  width: 39.4% !important;
  opacity: 0;
}

.tab3-vol2-figureThree-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab3FigureFourZero {
  position: absolute;
  bottom: 9.8%;
  left: 25.2%;
  width: 29% !important;
}

.tab3FigureFourZero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.seizureReplayButton {
  position: absolute;
  bottom: 5.8%;
  left: 42.4%;
  width: 12% !important;
}

.seizureReplayButton.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab2Figure3Footer {
  width: 60% !important;
}

.tab3FigureFourContent {
  position: absolute;
  position: absolute;
  font-size: 22px;
  font-family: 'din-2014-narrow';
  font-weight: bold;
  color: #5b2c86;
}

.tab3FigureFourOne {
  bottom: 26.6%;
  left: 15.4%;
}

.tab3FigureFourTwo {
  bottom: 63%;
  left: 38.8%;
}

.tab3FigureFourThree {
  bottom: 73.8%;
  left: 63.6%;
}

.tab3FigureFourFour {
  bottom: 79.8%;
  left: 87.5%;
}
.tab3FigureFourContent.animate__animated {
  animation-duration: 2s;
  animation-delay: 4s;
  opacity: 1;
}

.tab3-figure1 {
  bottom: 30.9%;
  z-index: 2;
}

.tab3-figure1.bar1 {
  left: 20%;
}
.tab3-figure1.bar2 {
  left: 25%;
}
.tab3-figure1.bar3 {
  left: 39%;
}
.tab3-figure1.bar4 {
  left: 43%;
}
.tab3-figure1.bar5 {
  left: 60%;
}
.tab3-figure1.bar6 {
  left: 65%;
}
.tab3-figure1.bar7 {
  left: 81%;
}
.tab3-figure1.bar8 {
  left: 85%;
}

.tab3-figure1-zero {
  position: absolute;
  bottom: 20.3%;
  left: 15.3%;
  width: 79.4% !important;
  opacity: 0;
}
.tab3-figure1-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab3-figure2-vol3 {
  bottom: 28.8%;
  z-index: 2;
}

.tab3-figure2-vol3.bar1 {
  left: 18%;
}
.tab3-figure2-vol3.bar2 {
  left: 21%;
}
.tab3-figure2-vol3.bar3 {
  left: 24%;
}
.tab3-figure2-vol3.bar4 {
  left: 27%;
}
.tab3-figure2-vol3.bar5 {
  left: 39%;
}
.tab3-figure2-vol3.bar6 {
  left: 42%;
}
.tab3-figure2-vol3.bar7 {
  left: 45%;
}
.tab3-figure2-vol3.bar8 {
  left: 48%;
}
.tab3-figure2-vol3.bar9 {
  left: 60%;
}
.tab3-figure2-vol3.bar10 {
  left: 63%;
}
.tab3-figure2-vol3.bar11 {
  left: 66%;
}
.tab3-figure2-vol3.bar12 {
  left: 69%;
}
.tab3-figure2-vol3.bar13 {
  left: 80%;
}
.tab3-figure2-vol3.bar14 {
  left: 83%;
}
.tab3-figure2-vol3.bar15 {
  left: 86%;
}
.tab3-figure2-vol3.bar16 {
  left: 89%;
}
.tab3-figure2-vol3-zero {
  position: absolute;
  bottom: 20.2%;
  left: 13.9%;
  width: 81.1% !important;
  opacity: 0;
}
.tab3-figure2-vol3-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab3-figure3-vol3 {
  bottom: 38.1%;
  z-index: 2;
}

.tab3-figure3-vol3.bar1 {
  left: 21.2%;
}
.tab3-figure3-vol3.bar2 {
  left: 39.7%;
}
.tab3-figure3-vol3.bar3 {
  left: 61.6%;
}
.tab3-figure3-vol3.bar4 {
  left: 81.6%;
}

.tab3-figure3-vol3-zero {
  position: absolute;
  bottom: 28.3%;
  left: 15.3%;
  width: 79.4% !important;
  opacity: 0;
}
.tab3-figure3-vol3-zero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.circle-header {
  font-size: 16px;
  font-family: 'din-2014';
  font-weight: bold;
}

.circle-header.red {
  color: #dc1e34;
}
.circle-header.purple {
  color: #63278a;
}

.circle-header.orange {
  color: #f78d2a;
}

.tab3-fig4-vol4 {
  bottom: 41.9%;
  z-index: 2;
}

.tab3-fig4-vol4.bar1 {
  left: 30%;
}
.tab3-fig4-vol4.bar2 {
  left: 42%;
}
.tab3-fig4-vol4.bar3 {
  left: 54%;
}
.tab3-fig4-vol4.bar4 {
  left: 66%;
}

.tab3Vol4FigureFourZero {
  position: absolute;
  bottom: -0.7%;
  left: 100.3%;
  width: 40.3% !important;
  opacity: 0;
}
.tab3Vol4FigureFourZero.animate__animated {
  animation-duration: 2s;
  animation-delay: 3s;
  opacity: 1;
}

.tab3-fig5-vol4 {
  bottom: 29.7%;
  z-index: 2;
}

.tab3-fig5-vol4.bar1 {
  left: 20%;
}
.tab3-fig5-vol4.bar2 {
  left: 24%;
}
.tab3-fig5-vol4.bar3 {
  left: 28%;
}
.tab3-fig5-vol4.bar4 {
  left: 32%;
}

.tab3-fig5-vol4.bar5 {
  left: 43%;
}
.tab3-fig5-vol4.bar6 {
  left: 47%;
}
.tab3-fig5-vol4.bar7 {
  left: 51%;
}
.tab3-fig5-vol4.bar8 {
  left: 55%;
}

.tab3-fig5-vol4.bar9 {
  left: 66%;
}
.tab3-fig5-vol4.bar10 {
  left: 70%;
}
.tab3-fig5-vol4.bar11 {
  left: 74%;
}
.tab3-fig5-vol4.bar12 {
  left: 78%;
}
