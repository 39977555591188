.itc-footer {
  font-size: 18px;
  padding: 30px;
  background: white !important;
  display: flex;
  flex-direction: row;
  a,
  div,
  span {
    color: black;
  }


  li {list-style: none;}
  a {
    &:hover {
      background-color:#ce1f4a;
      // background-image: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
      font-weight: bold;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.rainbow-bar{
  height: 14px;
  margin-top: -8px;
}

@media screen and (max-width: 1423px) {
  .rainbow-bar{
    margin-top: -9px;
  }
}
@media screen and (max-width: 1118px) {
  .rainbow-bar{
    margin-top: -10px;
  }
}

.absoluteVisible {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 999999999;
}


.itc-footer-right-links {
  display: flex;
  justify-content: flex-end;
}
.itc-footer-right {
  padding-left: 5px;
}
.itc-footer-right-links {
  display: block;
  padding-left: 0;
  text-align: right;
  // font-size: .9rem !important;
}
.itc-footer-right-links li {
  display: inline-block;
}

.itc-footer-logo {
  width: 100%;
  max-width: 300px;
}

.itc-footer-right-trademark {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .itc-footer {
    font-size: 9px;
    flex-direction: column;
    justify-content: center;
  }
  .itc-footer-right ul {
    text-align: left;
  }
  .itc-footer-right-trademark {
    text-align: left;
  }
  .itc-footer-logo {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 980px) {
  .itc-footer {
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .itc-footer {
    font-size: 12px;
  }
  .itc-footer-logo {
    max-width: 200px;
  }
}
@media screen and (max-width: 830px) {
  .itc-footer {
    font-size: 10px;
  }
}
