@import './vars.scss';

// $darkPurple: #5B2C86;

.survey-content {
  padding: 20px 40px;
  max-width: 1450px;
}

.question-text {
  color: $darkPurple;
  font-size: 1.15rem;
  line-height: 1.5rem;
  font-weight: 900;
  margin-bottom: 10px;
}

.row-to-column {
  display: flex;
}

.survey-content-separator {
  height: 30px;
}

.answerRow {
  margin-left: 20px !important;
  margin-bottom: 0 !important;
  padding: 14px 0 !important;
  border-bottom: 2px solid #ffffff !important;
  max-width: 80vw !important;

  .radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .radio-button {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      text-align: center;
      // min-width: 80px;
    }

    .radio-button::after {
      display: none;
    }
  }
}

.oddRow {
  background-color: #edf1f4;
}
.evenRow {
  background-color: #dbe4ea;
}

.otherFieldWrapper {
  width: 80% !important;
  max-width: 500px !important;
  margin-left: 40px !important;
  margin-top: -20px !important;
}

.otherFieldTextWrapper {
  display: none !important;
}

.q5otherFieldTextWrapper {
  display: none !important;
}

.q5otherFieldWrapper {
  height: 60px !important;
  margin-top: -2px !important;
  margin-left: 20px;
  padding-left: 20px;
  max-width: 80vw;
}

.rowQuestion {
  min-width: 280px;
  display: inline-block;
  color: $darkPurple;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 0;
}

.submit-button-wrapper {
  width: 100%;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: -150px;
  display: flex;
  justify-content: center;
}

.clearButton {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .survey-content {
    padding: 20px 20px 20px 10px;
    overflow: hidden;
  }

  .row-to-column {
    flex-direction: column;
  }

  .answerRow {
    max-width: unset !important;
    // width: 100%;
    margin-left: 4px !important;
    max-width: fit-content;
  }

  .otherFieldWrapper {
    margin-left: 40px !important;
  }

  .q5otherFieldWrapper {
    margin-left: 4px;
    padding-left: 4px;
    margin-right: -5px;
    max-width: 100%;
  }

  .rowQuestion {
    margin-left: 8px;
    margin-bottom: 10px;
  }

  .clearButton {
    margin: 0 auto;
  }
}

@media screen and (max-width: 650px) {
  .submit-button-wrapper {
    margin-bottom: unset;
  }
}

@media screen and (max-width: 600px) {
  .otherFieldInputWrapper {
    display: none !important;
  }
  .otherFieldTextWrapper {
    display: block !important;
    max-width: 80vw !important;
    margin-left: 40px !important;
  }

  .q5otherFieldWrapper {
    height: 125px !important;
    padding-left: 20px;
  }

  .q5otherFieldInputWrapper {
    display: none !important;
  }

  .q5otherFieldTextWrapper {
    display: block !important;
  }
}
