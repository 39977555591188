.title {
  margin-top: 10px;
}

div.search {
  margin-bottom: 0;
}

@media screen and (max-width: 650px) {
  .title {
    margin-top: 0;
    width: 100%;
    text-align: center;
  }

  .divider {
    border-top: 1px solid #624b78;
  }
}
