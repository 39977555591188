// $blue: #5B2C86;
// $lightblue: #c8f1f9;
// $verylightblue: #e2f8fc;
// $green: #5B2C86;
// $darkgrey: #3f3f3f;
// $darkPurple: #5B2C86;
// $white: #ffffff;
// $purpler: #463a5b;
// $yellow: #fae053;
// $turqoise: #5B2C86;
// $red: #ce1f4a;
// $font: #54565b;
// $darkRed: rgb(202, 53, 60);
// $fontSize: 17px;
// $font-family: 'Arial', sans-serif;
// $font-family-bold: 'Arial Bold', sans-serif;
// $font-family-medium: 'Arial Medium', sans-serif;

// $greyGradient: linear-gradient(#A4BBCA, #FFF, #A4BBCA);

// $purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .1) 3px, rgba(255, 255, 255, .1) 6px), linear-gradient($red, $darkPurple);

@import './_vars.scss';

* {
  box-sizing: border-box;
  outline: none;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.makeStyles-root-13 {
  position: relative;
  z-index: 2;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: auto;
  overflow: auto;
  color: #5B2C86;
  font-size: $fontSize;
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $fontSize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'din-2014';
  color: #54565b;

  b {
    font-family: 'din-2014';
  }
}

small {
  font-family: 'din-2014';
}

sup {
  font-family: 'din-2014';
}

html.game body {
  padding-top: 0 !important;
  background: none;
}

.no-padding {
  padding: 0 !important;
}

#root {
  display: flex;
  flex-direction: column;
}

.root-subwrapper {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.flex .inline-flex {
  display: inline-flex;
}

.flex .direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

header {

  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 17px;

  li {
    font-family: $font-family;
  }
}

.ant-layout-header {
  z-index: 999999999;
}

main {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
  z-index: 1;
}

.ant-layout {
  min-height: 100%;
}

// .ant-layout-header {
//   // padding: 0 50px;
// }

.image-container {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.parallax-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  perspective: 1200px;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.overlay {
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  position: absolute;
}

img {
  max-width: 100%;
  transform: translateZ(0);
}

.login-row {
  width: 100%;
  height: calc(100vh - 64px - 48px);
  overflow-y: hidden;
  display: flex;
}

.login-bg .paragraph {
  color: white;
  font-size: $fontSize;
}

.ant-menu,
.ant-layout-header .ant-menu {
  line-height: unset !important;
  color: $green;

}

li.ant-menu-item {
  color: $darkPurple;
}

// .ant-body,
// .ant-spin-nested-loading,
// .ant-spin-container {
//   height: 100% !important;
// }

.ant-menu li,
.ant-layout-header .ant-menu li {
  color: $darkPurple;
  line-height: unset !important;
}

.ant-menu .ant-menu-item-selected,
.ant-layout-header .ant-menu .ant-menu-item-selected,
.ant-menu-item-active {
  color: $red !important;
}

.btn__outline--purple {
  border: 2px solid #624b78;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
}


.btn__outline--green {
  border: 2px solid s$darkPurple !important;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
  color: white;
}

.btn__solid--purple {
  border: 2px solid #624b78;
  background-color: #624b78;
  background-image: linear-gradient(135deg, #624b78 0%, #9599e2 100%);
  border-radius: 6px;
  padding: 6px 10px;

  &:hover {
    -webkit-text-fill-color: white;
  }
}

.jumbotron__primary {
  background-size: cover !important;
  width: 100%;
  height: 500px;
}

.isi-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.ant-layout-header ul {
  position: relative;
  top: -2px;
}

.ant-card {
  border-radius: 6px;
  border: none;
  overflow: hidden;
}

.ant-card .ant-card-extra {
  background: white;
  height: 100%;
  font-size: inherit;
  width: 100px;
  text-align: center;
}

.ant-card .ant-card-extra svg {
  fill: $darkPurple !important;
  font-weight: bold;
}

.ant-card-head {
  border: none;
  background: #A4BBCA !important;
  background-size: cover !important;
  color: white;
  padding-right: 0;
  border-bottom: 0;
  color: #fae053;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 34px;
  margin-bottom: 1px;
}

.ant-card-head .ant-card-head-title {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-card-head .title {
  padding: 10px;
}

.ant-card-head .ant-card-extra {
  padding: 4px 0;
}

.ant-card-head .ant-card-extra a {
  color: #624b78;
}

.ant-select-dropdown {
  z-index: 999999999 !important;
}

.gradient--green {
  background-color: #dfeaef;
}

.line {
  flex: auto;
  background: rgba(0, 0, 0, 0.3);
  height: 1px;
}

input[type='submit']:disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}


form span {
  color: #3f3f3f;
}

form input {
  height: 40px;
}

.title--purple,
.text--purple {
  color: $darkPurple !important;
  font-size: 30px;
  line-height: 1.2;
}

.text--turqoise {
  color: $darkPurple !important;
}

.text__white {
  color: white !important;
}

.text__yellow {
  color: #fae053 !important;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

@import '~antd/dist/antd.css';

.makeStyles-root-13 {
  position: relative;
  z-index: 2;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: auto;
  background-size: cover;
}

.game {

  body,
  #root {
    background: none !important;
  }
}

// .webcasts {
//     background: url('../img/webcasts_bg.png') !important;
//     background-size: 100% !important;
//     background-repeat: no-repeat;
// }

.wrapper {
  // max-width: 870px;
  margin: 0 auto;
  // background: rgba(0,0,0,.1);
  display: block;
  width: 100%;
}

.padding {
  padding: 0 20px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .inline-flex {
    display: inline-flex;
  }

  .direction-column {
    flex-direction: column;
  }
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

::selection {
  color: white !important;
  background: white !important;
}

header {
  display: block;
  padding-bottom: 5px !important;
  background:
    linear-gradient(to left,
      #AAC0CD 0%,
      #FFFFFF 50%,
      #AAC0CD 100%) left bottom #FFF no-repeat !important;
  background-size: 100% 5px !important;
  position: relative;
  z-index: 999999999;

  ul {
    background: none !important;
  }
}

.ant-layout-header {
  z-index: 999999999;
  background: white;
  height: auto !important;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 180px;
    z-index: -1;
  }

  li,
  a {
    transition: all 0.3s ease-in-out;

    &:not(button a):hover,
    &::hover span {
      background-color: #00b4e1;
      background-image: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
      font-weight: bold;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.home-page .session-card {
  max-width: 100%;
}

main {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
  z-index: 1;
}

.ant-layout {
  min-height: 100%;
}

a.ant-typography,
.ant-typography a {
  color: #54565b;
}

#register_form .ant-typography {
  color: $red !important;
}

.ant-menu,
.ant-layout-header .ant-menu {
  line-height: unset !important;
  // & li {
  //   color: $darkPurple;
  //   line-height: unset !important;
  // }

  .ant-menu-item-selected {
    color: $red !important;
  }
}

.btn__outline--purple {
  border: 2px solid $darkPurple;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
}

.btn__solid--purple {
  border: 2px solid $darkPurple;
  background: $darkPurple;
  color: white !important;
  border-radius: 6px;
  padding: 6px 10px;
  transition: all 0.3s ease-in-out;
}

.jumbotron__primary {
  background-size: cover !important;
  width: 100%;
  height: 500px;
}

.ant-card {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ant-layout-footer {
  background: $darkPurple;
  padding: 0 !important;
}

.isi {
  width: 100%;
  background: $purpler;
}

.isi-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.isi-body-wrapper span {
  font-weight: 800;
}

.isi-body-wrapper span,
.isi-body-wrapper div,
.isi-body-wrapper b {
  font-family: 'din-2014';
}


.texttt {
  color: $white;
  font-family: $font-family;
  max-width: 700px;
  margin: 30px 100px;
}

div.isi-wrapper>div.texttt>* {
  margin-bottom: 10px;
}

.ant-layout-header ul {
  position: relative;
  top: -2px;
}

.ant-card {
  border-radius: 6px;
  border: none;
  overflow: hidden;
  //   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e2e2e2+100 */
  //   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ededed+100 */
  // background: rgb(255,255,255); /* Old browsers */
  // background: -moz-linear-gradient(-45deg,  rgba(255,255,255,1) 0%, rgba(237,237,237,1) 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(-45deg,  rgba(255,255,255,1) 0%,rgba(237,237,237,1) 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(135deg,  rgba(255,255,255,1) 0%,rgba(237,237,237,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .ant-card-extra {
    background: white;
    height: 100%;
    font-size: inherit;
    width: 100px;
    text-align: center;

    svg {
      fill: $turqoise !important;
      font-weight: bold;
    }
  }

  .ant-card-body {
    background: transparent;
    color: #54565b;

    span {
      color: #54565b;
    }

    .ant-btn span {
      color: $darkPurple !important;
      font-weight: 800;
    }
  }
}

.ant-card-head {
  border: none;
  background: #a4bbca;
  background-size: cover !important;
  color: white;
  padding-right: 0;
  padding-left: 0;
  border-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 34px;

  .ant-card-head-title {
    font-family: 'din-2014-narrow';
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #d3dde4;
  }

  .title {
    padding: 10px;
  }

  .ant-card-extra {
    padding: 4px 0;
  }

  .ant-card-extra a {
    color: $darkPurple;
  }
}

.ant-select-dropdown {
  z-index: 999999999 !important;
}

.gradient--green {
  background-color: #dfeaef;
}

.line {
  flex: auto;
  background: rgba(0, 0, 0, 0.3);
  height: 1px;
}

.ant-modal-mask {
  z-index: 999999999;
}

.ant-modal-wrap {
  z-index: 999999999;
}

@import './buttons.scss';
@import './forms.scss';
@import './typography.scss';

.ant-modal-mask {
  z-index: 999999999;
}

.ant-modal-wrap {
  z-index: 999999999;
}

.ant-modal-confirm-btns {
  float: unset !important;
  display: flex;
  justify-content: center;
}

.ant-select-dropdown {
  z-index: 999999999;
}

.ant-message {
  z-index: 999999999;
}

.logo {
  width: auto;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px;

    .logo {
      width: 150px;
      height: auto;
    }
  }

  .r-col {
    margin: 0 auto;
  }

  .interest-topics {
    background-size: cover !important;

    .column-wrap {
      padding-left: 20px;
    }

    .column-wrap,
    .flex .ant-row {
      flex-direction: column;
      width: 100%;
      flex-flow: column;

      .ant-col-8 {

        &,
        & button {
          flex-basis: 100%;
          max-width: 100% !important;
        }
      }
    }
  }
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background: linear-gradient(0.9turn,
      $lightblue,
      $darkPurple,
      lighten($lightblue, 50%),
      $darkPurple);
  z-index: 999999999;
  background-size: 400% 400%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .loader-logo {
    width: 100%;
    max-width: 300px;
    position: absolute;
    align-self: center;
    z-index: 999999999;
  }
}

.ant-select-selector {
  min-height: 40px !important;
  padding: 5px 12px !important;
}

.hooks-main>svg {
  display: none;
}

.hooks-main>div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  background: #624b78;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: 0.6;
}

.hooks-main>div:nth-child(1) {
  width: 120px;
  height: 120px;
}

.hooks-main>div:nth-child(2) {
  width: 250px;
  height: 250px;
}

.hooks-main>div:nth-child(3) {
  width: 150px;
  height: 150px;
}

.hooks-main>div::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgba(0, 167, 181, 0.8);
}

.hooks-main>div:nth-child(2)::after {
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
}

.hooks-main>div:nth-child(3)::after {
  top: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
}

.hooks-main {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: url('#goo');
  overflow: hidden;
}

.ant-modal-body {
  button {
    width: 100%;
    max-width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item {
    margin: 0;
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  #register_form #user_lookup_form {

    .flex,
    .ant-row.ant-form-item {
      max-width: 100% !important;
      width: 100% !important;
      display: block;
    }
  }
}

// ie bug fix
div.ant-row.ant-form-item>div.ant-col.ant-col-24.ant-form-item-control {
  margin-bottom: 20px;
}

// ie bug fix
div.ant-col.ant-col-24.ant-form-item-control>div.ant-form-item-control-input {
  margin-bottom: 10px;
}

.ant-modal-body button {
  max-width: 100% !important;
}

::selection {
  background-color: #624b78 !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(155, 155, 155, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
    box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 30px rgba(155, 155, 155, 0);
    box-shadow: 0 0 0 30px rgba(155, 155, 155, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
    box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
  }
}

.glow-box,
.btn-dark,
.score-wrapper .btn-medium {
  cursor: pointer;
  animation: pulse 0.8s infinite;
  border: 1px solid transparent;
}

.btn-dark:disabled,
.score-wrapper .btn-medium:disabled {
  animation: none;
}

.glow-box:hover,
.btn-dark:hover,
.btn-medium:hover {
  cursor: pointer;
}

.isi-backup {
  z-index: 999999999 !important;
}

@media screen and (max-width: 768px) {
  form .flex {
    flex-direction: column !important;

    input {
      width: 100%;
    }
  }

  form,
  .ant-row.ant-form-item {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .ant-col-lg-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 400px;
    max-width: 400px !important;
    flex: none;
  }
}

.interest-topics-bg {
  background: url('../img/My_interests_background.png');
  background-size: cover !important;
  background-position: center;
}

:required {
  background: $red;
}

.ant-input-disabled {
  background-color: $red;
}

li.ant-menu-item {
  margin: 0px !important;
}

.ant-menu li,
.ant-layout-header .ant-menu li,
.ant-menu li,
.ant-layout-header .ant-menu li {
  &:visited {
    color: $darkPurple;
  }

  color: $darkPurple !important;

  &:hover {
    color: $red !important;
  }
}

.ant-menu .ant-menu-item-selected,
.ant-layout-header .ant-menu .ant-menu-item-selected {
  color: $red !important;
}

.footer-styles {
  margin-right: 6px;
}

.profile-reg-form label {
  font-weight: 100 !important;
  font-family: $font-family;
}

li.ant-menu-item {
  margin: 0 !important;
  padding: 0 15px !important;
}

// .ant-checkbox .ant-checkbox-inner {
//   background-image: url('../img/unchecked.png') !important;
//   background-color: transparent !important;
//   background-size: cover !important;
//   height: 16px;
//   width: 16px;
//   display: inline-block;
//   padding: 0 0 0 0px;
// }

// .ant-checkbox-checked .ant-checkbox-inner {
//   background-image: url('../img/checked.png');
//   background-color: transparent !important;
//   background-size: cover !important;
//   height: 16px;
//   width: 16px;
//   display: inline-block;
//   padding: 0 0 0 0px;
// }
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  display: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFFFFF;
  border-color: #B3B3B2;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #B3B3B2;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 0px;
  font-size: 14px;
  font-family: $font-family;
  line-height: 1;
  content: '*';
}

.ant-card .ant-card-extra svg {
  fill: #D10027 !important;
  font-weight: bold;
}

.ant-card-head .ant-card-extra {
  padding: 5px 0;
}

.itcInputsCheckbox {
  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #FFFFFF;
    }
  }

  .ant-checkbox-inner {
    border-color: #B3B3B2;
    background-color: $darkPurple;
    border: 1px solid #FFFFFF;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #AC89B5;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
}

button.share-submit-btn {
  padding: 5px 20px !important;
  border: 1px solid #A3A0AE !important;
  color: #615571 !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  cursor: pointer;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  z-index: 999999;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  // padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  width: 100%;

  // padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 20px 0;

  span {
    font-family: 'din-2014-narrow';
  }

  // margin-bottom: 30px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.8);
}


h4.ant-typography,
.ant-typography h4 {
  color: $darkPurple;
}

.purple-btn,
.purple-btn button {
  background: $darkPurple;
}

.ant-menu li,
.ant-layout-header .ant-menu li,
.ant-menu li,
.ant-layout-header .ant-menu li {
  color: $darkPurple !important;
}

li.ant-menu-item,
li.ant-menu-item a {
  color: $darkPurple
}

//  svg path {fill: #D10027;}

.ant-menu-item-seleted span {
  color: $red !important;
}

.bg-layer,
.red-gradient {
  min-height: 260px;
  height: auto;
  max-height: auto !important;
  width: 100% !important;
  font-optical-sizing: none;
  display: block;
  position: static !important;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  background-size: cover !important;
  background: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .1) 3px, rgba(255, 255, 255, .1) 6px), linear-gradient(#D10027, $darkPurple);
}

.bg-layer,
.purple-gradient {
  min-height: 260px;
  height: auto;
  max-height: auto !important;
  width: 100% !important;
  font-optical-sizing: none;
  display: block;
  position: static !important;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;

  background-size: cover !important;
  background: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .1) 3px, rgba(255, 255, 255, .1) 6px), linear-gradient($darkPurple, #D10027);


  img {
    width: 250px !important;
  }
}

.isi-preview {
  border-width: inherit 5px 0 0 0;
  border-image-source: url('../img/isibar.png') !important;
}

.isi {
  color: black;
}


.bm-menu,
.bm-menu a {
  padding: 20px 0p;
  background: $darkPurple;
  color: white;
  border-bottom: white solid 2px;
  display: flex;
  justify-content: space-between;
  width: 100%;


  span {
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.npi-lookup-modal .ant-modal-content {
  border-radius: 10px;
}


.register-form-wrapper {
  width: 75vw;
  margin: 50px auto;
  border: 1px solid gray;
  padding: 50px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .register-form-wrapper {
    width: 100vw;
  }
}


@media screen and (max-width: 825px) {

  .red-gradient,
  .bg-layer {
    height: auto;
    max-height: auto !important;
    flex-direction: column;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center !important;

    img {
      //width: 50% !important;
      display: block !important;
      //margin: 0 auto !important;
    }

    .masterclass-mobile-logo {
      width: 100% !important;
    }

  }
}

@media screen and (max-width: 1330px) {
  .header-text {
    margin: 0 20px;
  }

  .desktop {
    display: none;
  }

  .desktop-block {
    padding: 0 !important;
    display: block;
    width: 100%;
    flex: none !important;
  }

  .flex {
    flex-direction: column;
  }

  // lets keep the flex direction to row for the register-form-wrapper class
  .register-form-wrapper {
    .flex {
      flex-direction: row;
    }
  }

}

.ant-layout-header img.logo {
  height: 70px !important;
}

@media screen and (max-width: 550px) {

  .red-gradient,
  .bg-layer {
    flex-direction: column !important;
  }

  .ant-layout-header img.logo {
    height: 50px !important;
  }
}

@media screen and (max-width: 948px) {
  .bg-layer {
    .layout.vbooth {
      width: 100% !important;
      flex-direction: column !important;
      height: auto !important;
      max-height: auto !important;

      & .flex {
        align-items: center !important;
        margin-bottom: 20px;
      }

      img {
        align-self: center;
        width: auto !important;
        margin: 20px auto;
      }

      img.laptop {
        width: 70% !important;
      }

      h3,
      span {
        text-align: left;
        margin: 10 auto !important;
        max-width: 90%;
      }

      .border-button {
        margin: 20px;
      }
    }

  }

  .npiFormCheck {
    width: 100% !important;
  }


  .register-form-wrapper {

    .flex {
      flex-direction: column;
      .ant-row.ant-form-item, .form-item-group {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 1330px) {
    // lets keep the flex direction to row for the register-form-wrapper class at this width
    .register-form-wrapper {
      .flex {
        flex-direction: row;
      }
    }
}

  .purple {
    color: $darkPurple !important;
  }

  .vboothbutton button {
    background: $darkPurple;
    color: white
  }

  img.laptop {

    margin-top: auto;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: 20px;
    margin-right: 50px;
    flex: 1;
    aspect-ratio: 2/1;
  }


  .layout {
    margin: 50px;

    & .layout {
      margin: 0 !important
    }
  }

  @media screen and (max-width: 768px) {
    .layout {
      margin: 20px;
    }

    .header-text {
      margin: 0 20px;
    }

    .layout.full {
      margin: 0 !important;
    }

    .xpert-bar {
      margin: 20px !important;
    }

    .policy-bar {
      margin: 30px !important;
    }
  }
}


.wrapper.uncollapsed {
  overflow-y: auto !important;
}

.bm-menu-wrap {
  height: auto !important;
  bottom: 0;
}



.ant-card-head {
  background: #a4bbca;
}

.gradient-text {
  background-size: 200% 100%;
  background: linear-gradient(0.145turn, $darkPurple, $red, $darkPurple) !important;
  background-clip: text !important;
  color: transparent !important;
  transition: all 0.5s ease-in-out;
  background-position: left center;
}

.gradient-text:hover {
  background-position: right center !important;
}

.bottom-top {
  line-height: 1.57 !important;
}

.wrapper div {
  color: $font !important;
}

.ant-layout-content {
  min-height: 100% !important;
}

.share-email {
  display: flex;
  flex-direction: column !important;
}

.share-email .border-button-wrapper {
  max-width: 100% !important;
  width: 100%;
}

::before {
  color: #000 !important;
}

.ant-modal-content {
  button[type='button'] {
    position: relative;
    font-size: 11px !important;
    text-align: center;
    border-radius: 30px !important;
    min-height: 30px;
    width: 100%;
    padding: 3px 20px;
    line-height: 1 !important;
    word-break: keep-all;
    white-space: nowrap;
    height: 40px;

    &.small {
      padding: 0;
    }

    &,
    span {
      color: white !important;
    }

    border: 1px solid white;
    border-color: rgba(255, 255, 255, 1);
    background: linear-gradient(to right, #5B2C86 0%, #DC1E34 50%, #5B2C86 100%);
    text-transform: uppercase;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {

      border-color: white;
    }


  }

  .purple-btn {
    background-color: $darkPurple !important;
    border-color: $darkPurple;
  }

  .border-button-wrapper {
    background-color: #5B2C86;
    /* IE6-9 */
    background-size: 100% 100%;
    border-radius: 30px;
    width: 100%;
    font-weight: 800;
    cursor: pointer;
    transition: transform background-position 0.3s cubic-bezier(0.31, 0.44, 0.445, 1.65);
  }

  .border-button-wrapper:hover {
    transform: scale(0.98);
    background-position: 100% 0;
    border-color: $red;
    background-size: 300% !important;
  }

  button[type='button'],
  button[type='button'] span {
    color: white !important;
  }

  button[type='button']:hover,
  button[type='button']:focus {
    border-color: white;
  }


}

.ant-modal-body .purple {
  text-align: center;
  color: #424348 !important;
  font-size: 30px;
}



.ant-checkbox-wrapper span {
  font-weight: bold;
  font-family: $font-family;
}

.ant-modal-close {
  background: none !important;
  background-color: transparent !important;
  margin-left: auto !important;
}

.ant-modal-close-x {
  margin-left: auto !important;
}

//reset
.ant-modal-body .ant-checkbox-wrapper span {
  font-family: $font-family;
  ;
  font-weight: 500;
}

.button-row-modal-footer {
  display: flex;
  flex-direction: row;
  margin: 10px;

  @media screen and (max-width: 500px) {
    flex-direction: column;

    .border-button-wrapper {
      margin: 8px auto !important;
    }
  }
}

.fit {
  overflow-y: auto !important;
  max-height: 500px;
  position: fixed;
}




.ant-menu-title-content span {
  font-size: $fontSize;
  font-weight: bold;
}

#register-form-summary {
  font-size: 17px;
}




@media screen and (max-width: 768px) {

  #root .collapsed {
    padding-top: 26px;
    font-size: 14px !important;
  }

}

.uncollapsed {
  font-size: $fontSize !important;
}


.mobile-carot-arrow {
  border: 1px solid $red;
  width: 8px;
  height: 8px;
  transform: rotateY(0deg) rotate(45deg) !important;
  border-left: none;
  border-bottom: none;
  margin-left: 10px;
}

.sub-menu-items {
  background-color: rgb(2468, 233, 235) !important;
  padding-left: 20px;
  //PUT IN ANIMATION HERE
  display: none !important;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;

  span {
    color: red !important;
    font-weight: 500 !important;
    font-family: 'din-2014-narrow' !important;
  }

  &.active {
    display: block !important;
    visibility: visible;
    opacity: 1;
  }
}

.right-outlined-class {
  color: white !important;
  padding-right: 20px;

  svg path {
    color: white !important;
    fill: white !important;
  }
}

//OVERWRITE FOR TAB STYLE
.ant-tabs-tab-btn {
  font-family: 'din-2014-narrow' !important;
  font-size: 16px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  font-weight: bold;
}

.ant-tabs-ink-bar {
  background: $red;
  height: 4px !important;
}

.ant-tabs-tab:hover {
  color: $red;
}

.bm-cross {
  display: none !important;
}

.epi-header {
  color: white !important;

  span {
    display: inline-block !important;
  }

  span::first-letter {
    font-weight: bold !important;
  }
}

.mobile-widget-margin {
  margin-top: 20px;
}

#mobile-widget-divider {
  display: none;
}

.widget-wrapper {
  padding: 30px;
  background-color: #ECF2F6;
  max-width: 350px;
  line-height: 1.2 !important;

  h4 {
    font-family: 'din-2014';
    font-weight: bold;
  }
}

.register-widget-wrapper-container {
  // applies/used only to the /auth/register path
  margin: 25px 10px;
  min-width: 500px;
}

.register-widget-wrapper {
  text-align: left;
  max-width: unset;
  min-height: 300px;
}

.register-widget {
  margin-top: 20px;
}


@media screen and (max-width: 550px) {
  .register-widget-wrapper-container {
    min-width: unset;
  }


}

.ant-form-item-label>label {
  align-items: start;
}


@media screen and (max-width: 991px) {
  .mobile-widget-margin {
    // margin-top: 0px;
    // margin-left: -20px;
    // margin-right: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // .widget-wrapper {
  //   width: 80%;
  // }

  #mobile-widget-divider {
    display: block;
  }

  .register-widget {
    padding: 5px 30px 30px 30px;
    margin-top: 0px;
  }
}


