
.masterclass-bar {
  display: flex;
  // flexDirection: isMobile ? 'column' : 'row',
  background: #FEEEDF;
  margin: 50px;
  padding: 20px;
  font-size: 20px;
  line-height: 1.2;
}

.masterclass-logo {
  // width: 150px;
  margin-right: 60px;
  height: 150px;
}

.masterclass-text {
  text-align: left;
}


@media screen and (max-width: 768px) {
  .masterclass-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .masterclass-logo {
    margin-right: unset;
    margin-bottom: 20px;
    object-fit: contain;
    height: 100px;
  }

  .masterclass-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
