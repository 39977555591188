// $blue: #5B2C86;
// $lightblue: #c8f1f9;
// $verylightblue: #e2f8fc;
// $green: #5B2C86;
// $darkgrey: #3f3f3f;
// $darkPurple: #5B2C86;
// $white: #ffffff;
// $purpler: #463a5b;
// $yellow: #fae053;
// $turqoise: #5B2C86;
// $red: #ce1f4a;
// $font: #54565b;
// $font-family: 'Arial', sans-serif;
// $font-family-bold: 'Arial Bold', sans-serif;
// $font-family-medium: 'Arial Medium', sans-serif;

// $purpleGradient: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255,255,255, .1) 3px, rgba(255,255,255, .1) 6px), linear-gradient($red, $darkPurple);


@import 'vars';


.icons svg,
path {
  color: $darkPurple !important;
}
.session-card,
.SessionCard {
  color: $darkgrey;
  font-size: 12px;
  text-align: left;
  transition: all 0.3 cubic-bezier(0.31, 0.44, 0.445, 1.65);
  max-width: 800px;
  box-shadow: -5px -5px 30px 5px $lightblue, 5px 5px 30px 5px $darkPurple;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto !important;

  h2.title--purple {
    color: $darkPurple !important;
font-family:$font-family-bold;
    padding-right: 50px;
    font-size: 36px;
    line-height: 1.2;
  }
  .ant-typography { color: #54565b !important; }
  h2.title--green {
    color: $darkPurple;
font-family: $font-family-bold;
    padding-right: 50px;
    font-size: 36px;
    line-height: 1.2;
  }
.vboothbutton {
  display: inline;
}
  .ant-card-body {
    flex: auto !important;
    padding: 10px !important;
  }
  .sub {
    color:$darkPurple !important;
  }
  h5 {
    color: $darkPurple !important;
  }
  &.small {
    h2 {
      font-size: 14px;
    }
  }

  .anticon {
    color: $darkPurple !important;
  }

  &.ant-card .ant-card-body {
    padding: 0 !important;
    font-size: 16px !important;
  }

  .ant-btn {font-size: 17px;
    svg {
      color: $darkPurple !important;
    }
  }

  .avatar-wrapper {
    padding: 0 20px;
    font-size: 0.8rem;
    line-height: 1.1;
  }
}

.alt-layout {
  max-width: 600px;

  h2.title--purple {
    font-size: 32px;
    padding: 0;
  }
}

.flex-column {
  flex-direction: column;
}

.session-card-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;

  .inline-flex {
    display: flex;
    margin-left: auto;
  }

}
.ant-btn { color: $darkPurple;font-size: 17px;
font-weight: 800;}

.session-card-footer-share-btn span, .session-card-footer-register-btn span {
font-family: $font-family;
  color: $darkPurple;
}

@media screen and (max-width: 1200px) {
  // .session-card,
  .ant-col {
    flex: 1;
  }

  .session-card.alt-layout {
    // max-width: 500px;
    h2.title--purple {
      font-size: 25px;
    }
  }

  .avatar-wrapper {
    padding-left: 0;
    border-bottom: 1px solid lightgrey;
    padding: 0 0 30px 0 !important;
  }

  .mobile-column {
    flex-direction: column-reverse !important;
    padding-left: 0;
    text-align: left;
    margin-bottom: 20px;
    color: $darkPurple !important;

    h2 {
      margin-top: 20px;
    }
  }

  .column-reverse {
    flex-direction: column-reverse;

    .ant-col {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 601px) {
  .session-card,
  .ant-row {
    flex-flow: column;
    flex-direction: column;
  }

  .ant-card {
    h2 {
      font-size: 20px;
    }

    .ant-typography {
      font-size: 12px;
    }

    .flex {
      flex-direction: column;
      flex-flow: column;
    }
  }
}

@media screen and (max-width: 601px) {
  .session-card-footer,
  .session-card-footer .inline-flex,
  .session-card-footer .horizontal-center {
    margin: 0 auto !important;
    flex-direction: column;
    width: 100%;
    max-width: 300px;

    button {
      width: 100%;
    }

    .border-button-wrapper,
    .flex-column {
      justify-content: center;
      align-items: center;

      flex-direction: column !important;
      margin: 10px !important;
    }
  }
}

@media screen and (max-width: 502px) {
  .session-card,
  .SessionCard {
    h2.title--purple {
      padding-right: 0px !important;
      font-size: 24px;
    }
  }
}

.check::before {
  background-color: $darkPurple !important;
  font-weight: bold;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.check {
  background-color: $darkPurple !important;
  font-weight: bold;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
color: $darkPurple;
}

.anticon-share-alt span {
  color: $darkPurple !important;
}
.anticon-share-alt svg path {
  color: $darkPurple;
}
.session-card-footer-register-btn {
  border: 2px solid $darkPurple !important;
}
